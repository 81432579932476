/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/** Types used in the file */
type argVal = string | number | Date | null | undefined

/**
 * Validate that the field is not null
 * @version 0.0.1
 * ```ts
 * @param {argVal} value input value
 * @return {boolean} true or false
 * ```
 */
export const isNull = (value: argVal): boolean => !(!!value || value === 0)

/**
 * Validate that the field is not numeric
 * @version 0.0.1
 * ```ts
 * @param {argVal} value input value
 * @return {boolean} true or false
 * ```
 */
export const isNumeric = (value: argVal): boolean => !!isNaN(Number(value))

/**
 * Validate that the field has a correct password format
 * @version 0.0.1
 * ```ts
 * @param {argVal} value input value
 * @return {boolean} true or false
 * ```
 */
export const isPassword = (value: argVal): boolean => {
    if (/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/.test(String(value)) !== true) return true
    return false
}

/**
 * Validate that the field contains only letters
 * @version 0.0.1
 * ```ts
 * @param {argVal} value input value
 * @return {boolean} true or false
 * ```
 */
export const onlyLetters = (value: string): boolean => {
    const validation = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g
    if (validation.test(value) || value.length === 0) return false
    return true
}

/**
 * Validate that the field is in the established range of letters
 * @version 0.0.1
 * ```ts
 * @param {string | number} value input value
 * @param {number} min minimum
 * @param {number} max maximum
 * @return {boolean} true or false
 * ```
 */
export const rangeLength = (value: string | number, min: number, max: number): boolean => {
    if (((String(value).length >= min) && (String(value).length <= max)) || String(value).length === 0) return false
    return true
}

/**
 * Validate that two values are equal
 * @version 0.0.1
 * ```ts
 * @param {argVal} valueOne value one
 * @param {argVal} valueTwo value two
 * @return {boolean} true or false
 * ```
 */
export const Match = (valueOne: argVal, valueTwo: argVal): boolean => {
    if (valueOne === valueTwo) return false
    return true
}

/**
 * Validate that the field has a correct email format
 * @version 0.0.1
 * ```ts
 * @param {string} email input value
 * @return {boolean} true or false
 * ```
 */
export const isEmail = (email: string): boolean => {
    const validation = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
    if (validation.test(email) || email.length === 0) return false
    return true
}

/**
 * Transform a number of numbers into money format
 * @version 0.0.1
 * ```ts
 * @param {string | number} value input value
 * @param {string} region region number format
 * @return {boolean} true or false
 * ```
 */
export const numberFormat = (value: string | number, region?: string): number | string => {
    if (value) {
        if (Number(String(value).replace(/\./g, ''))) return new Intl.NumberFormat(region || 'de-DE').format(parseFloat(String(value).replace(/\./g, '')))
        else return 0
    } else {
        if ((isNaN(Number(value)) || !value) && value !== 0) return ''
        else return 0
    }
}

/**
 * Busca un valor aleatorio de 10 caracteres
 * @version 0.0.1
 * @return {string} Valor aleatorio
 */
export const valRand = (): string => {
    /** variables necesarias */
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    /** creación de código */
    for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
    }

    return result
}

/**
 * Obtiene la información de la columna según el path dado ej: user.name
 * @param {string} path Key del objecto
 * @param {any} obj Objecto donde se va a buscar la key (path)
 * @return {void}
 */
// export const resolveData = (path?: string, obj?: any): any => path && path.split('.').reduce((prev, curr) => prev ? prev[curr] : null, obj)
export const resolveData = (myData: any, index: number | undefined, nameArray: string | undefined, name: string, array?: boolean, keys?: string[] | undefined): any => {
    let value = ''
    // Si coincide con la última clave
    for (const key in myData) {
        if (key === name && ((!!nameArray && !!array) || (!nameArray)) && !keys?.length) return myData[key]
        if (Array.isArray(myData[key])) {
            if (nameArray === key) {
                value = resolveData(myData[key][Number(index)], index, nameArray, name, !!nameArray)
            }
        } else if (typeof myData[key] === 'object' && (keys?.length ? keys[0] === key : true)) {
            value = resolveData(myData[key], index, nameArray, name, false, keys?.splice(1, keys.length))
        }
    }
    return value
}
/**
 * Transforma una fecha en ingles a formato español
 * @version 0.0.1
 * @param {string} value valor en números
 * @param {string | undefined} locale tipo de dirección
 * @return {string} nuevo formato de fecha
 */
export const dateFormat = (value: string, locale?: string): string => {
    const dateValue = new Date(value)
    return value && new Intl.DateTimeFormat(locale || 'es-CO', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(dateValue)
}

/**
 * Transforma un numero en formato de teléfono
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato en teléfono
 */
export const phoneFormat = (value: string): string => value//!!value && parsePhoneNumber(`${ value }`, 'US')?.formatNational()

/**
 * Transforma una hora en ingles a formato español
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato en hora
 */
export const hourFormat = (value: string): string => {
    const dateValue = new Date(value)
    return value && new Intl.DateTimeFormat('es-CO', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(dateValue)
}

export const validationImg = (file: File): boolean => (/\.(jpg|png|gif|jpeg)$/i).test(file.name)

/**
 * Busca la extension del archivo
 * @version 0.0.1
 * @param {string} filename nombre del archivo con la extension
 * @return {string} nombre de la extension
 */
export const extFile = (filename: string): string | undefined | null => {
    return (/[.]/.exec(filename)) ? (<any>/[^.]+$/).exec(filename)[0] : undefined;
}

/**
 * Actualiza la data del usuario según el tipo, si es array, array de objetos ó objeto plano
 * ```ts
 * @param {string[]} keys Claves del objeto donde va a buscar @example 'user.profile.name' equivale a: ['user', 'profile', 'name]
 * @param {number} indexSplit Índice a buscar dentro de las keys
 * @param {any} myData Data actual donde se va a buscar
 * @param {number} index Índice del array (si es un array)
 * @param {string} nameArray Nombre del la clave si es array
 * @param {string} name Name del input
 * @param {string | number} value valor del input
 * @returns {any[]} Devuelve el array o el objeto
 * ```
 */
export const updateData = (keys: string[], indexSplit: number, myData: any, index: number, nameArray: string, name: string, value: string | number | boolean): any => {
    // Si coincide con la última clave
    if (indexSplit === keys.length) {
        // Si es de tipo array
        if (Array.isArray(myData)) {
            return {
                ...myData[index],
                [name]: value
            }
        }
        // Si es de tipo objeto
        return {
            ...myData,
            [name]: value
        }
    } else {
        // Si la clave iterada coincide con el nameArray declarado en el Input
        if (keys[indexSplit] === nameArray) {
            // Si la clave iterada está dentro del array del input
            if (myData[keys[indexSplit]]?.[index]) {
                return {
                    ...myData,
                    [keys[indexSplit]]: myData[keys[indexSplit]].map((x: any, i: number) => {
                        // Si la posición del array coincide con la iteración del bucle
                        if (i === index) {
                            return updateData(keys, indexSplit + 1, myData[keys[indexSplit]][index] || {}, index, nameArray, name, value)
                        }
                        return x
                    })
                }
            } else {
                return {
                    ...myData,
                    [keys[indexSplit]]: myData[keys[indexSplit]]?.length ?
                        [...myData[keys[indexSplit]], updateData(keys, indexSplit + 1, {}, index, nameArray, name, value)]
                        :
                        [updateData(keys, indexSplit + 1, {}, index, nameArray, name, value)]
                }
            }
        } else {
            return {
                ...myData,
                [keys[indexSplit]]: {
                    ...myData[keys[indexSplit]],
                    ...updateData(keys, indexSplit + 1, myData[keys[indexSplit]] || {}, index, nameArray, name, value)
                }
            }
        }
    }
}

/**
 * @description Función que valida los formularios, funciona para trabajar los errores con estados
 * @version 0.1.1
 * @param {array} elements elementos del formulario
 * @return {array} devuelve un array de  con el nombre identificador para cada estado en react.
 */
export const validationSubmitHooks = <T>(elements: HTMLInputElement[]): { errorForm: T, isError: boolean } => {
    // Error vars
    let errorForm = {} as T, isError = false

    for (let i = 0; i < elements?.length; i++) {
        if (elements[i].name) {
            if (elements[i]?.dataset?.type === 'textarea' || elements[i].type === 'text' || elements[i].type === 'date' || elements[i].type === 'datetime-local' || elements[i].type === 'month' || elements[i].type === 'month' || elements[i].type === 'radio' || elements[i].type === 'range' || elements[i].type === 'search' || elements[i].type === 'tel' || elements[i].type === 'time' || elements[i].type === 'url' || elements[i].type === 'week' || elements[i].type === 'password' || elements[i].type === 'email' || elements[i].type === 'number' || elements[i].type === 'hidden') {

                // Desestructuración de datasets
                const { object, index, nameArray, required } = elements[i]?.dataset || {}
                if (object) {
                    errorForm = {
                        ...errorForm,
                        ...updateData(object?.split('.') as unknown as string[], 0, errorForm, Number(index) as number, nameArray as string, elements[i].name, required ? !elements[i].value : false)
                    }
                } else {
                    errorForm = { ...errorForm, [elements[i].name]: required ? !elements[i].value : false }
                }

                if (required && !elements[i].value) isError = true
            }
        }
    }

    return { errorForm, isError }
}

/**
 * busca en el localStore la información y la parsea si es necesario
 * @version 0.0.1
 * @param {*} key clave de búsqueda
 * @param {boolean} isParse si se quiere parsear o no
 * @return {boolean} devuelve el valor parseado o false si pudo guardar en localStorage
 */
export const getDataLS = <T>(key: string, isParse: boolean): boolean | T => {
    try {
        const jsonValue = window.localStorage.getItem(key)
        return isParse ? (jsonValue ? JSON.parse(jsonValue) : false) : jsonValue
    } catch (e) {
        return false
    }
}

/**
 * Verifica que las contraseñas cumplan con el estándar correcto
 * @version 0.0.1
 * @param {string} value valor
 * @return {boolean} true o false
 */
export const passwordFormat = (value: string): boolean => {
    const validar = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/
    if (validar.test(value) === true) {
        return false
    } else return true
}