import styled, { css, keyframes } from 'styled-components'
import { TLoading } from './types'

export const Container = styled.div<TLoading>`
    transform: translate(-50%,-50%);
    width: ${({ size }) => size || '50px'};
    height: ${({ size }) => size || '50px'};
    position: ${({ position }) => position || 'absolute'};
    ${({ left }) => left && css`left: ${left};`}
    ${({ right }) => right && css`right: ${right};`}
    ${({ top }) => top && css`top: ${top};`}
    ${({ bottom }) => bottom && css`bottom: ${bottom};`}
`
export const Animation = keyframes`
    0% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -105;
    }
    50% {
        stroke-dasharray: 80 10;
        stroke-dashoffset: -160;
    }
    100% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -300;
    }
`
export const Circle = styled.circle`
    transform-origin: center;
    animation-name: ${Animation};
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier;
    animation-iteration-count: infinite;
`