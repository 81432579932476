/* eslint-disable @typescript-eslint/no-explicit-any */
// Modules
import React, { useEffect, useState, FC, ReactElement } from 'react'
import ReactSelect, { ActionMeta, ValueType, Props as PropsSelect } from 'react-select'

// Components
import { Loading } from '../Loading'
import { InitConfigColor } from '../../utils/colors'
const Colors = InitConfigColor.getColors()

// Utils, Styles and Types
import { isNull, resolveData, valRand } from '../../utils/index';
import { BoxSelect, Container, LabelSelect, Tooltip } from './styled'
import { TSelect } from './types'
import { InitConfigSelect } from './settings'

/**
 * Select components
 * @version 0.0.1
 * ```ts
 * @param {TSelect} props properties Select
 * @return {ReactElement<TSelect>} node Select
 * ```
 */
export const Select: FC<PropsSelect | TSelect> = ({
    val,
    name,
    title,
    error,
    focus,
    nameId,
    dataCy,
    onClick,
    rowData,
    onSelect,
    required,
    isDisabled,
    nameObject,
    nameValueObject,
    nameCombineObject,
    dataIndex,
    nameArray,
    isLoading,
    dataObject,
    widthTable,
    widthPhone,
    mediaTable,
    mediaPhone,
    colorLoading,
    maxWidthTable,
    maxWidthPhone,
    loadingContainer,
    cWidth,
    cMargin,
    cStyles,
    cDisplay,
    cMaxWidth,
    cMinWidth,
    cPosition,
    cFlexDirection,
    cHoverDisplayTooltip,
    lTitle,
    lStyles,
    lBorder,
    lMargin,
    lPadding,
    lFontSize,
    lNotValue,
    lOverflow,
    lTextAlign,
    lWhiteSpace,
    lFontFamily,
    lErrorColor,
    lActiveTitle,
    lTextOverflow,
    lNotValueColor,
    lHoverDisplayTooltip,
    lColor,
    sTitle,
    sStyles,
    sTransform,
    sActiveTitle,
    sTransformMenu,
    tTop,
    tLeft,
    tColor,
    tZIndex,
    tBorder,
    tBottom,
    tPadding,
    tFontSize,
    tBoxShadow,
    tFontFamily,
    tBorderRadius,
    tTopAfterBefore,
    tLeftAfterBefore,
    tBackgroundColor,
    tBorderAfterBefore,
    tBottomAfterBefore,
    tContentAfterBefore,
    tDisplay,
    tPosition,
    tStyles,
    tPositionAfterBefore,
    tPointerEventsAfterBefore,
    sizeLoading,
    rightLoading,
    bottomLoading,
    valueSelectCustom,
    nameValue,
    data,
    errorMessageRequired,
    dataExtra,
    validation = true,
    theme,
    tiColor,
    tiZIndex,
    tiStyles,
    tiOpacity,
    tiPadding,
    tiFontSize,
    tiMaxWidth,
    tiMaxHeight,
    tiVisibility,
    tiFontFamily,
    tiBorderRadius,
    tiBackgroundColor,
    tiPosition,
    tiOverflow,
    tiWhiteSpace,
    tiTextOverflow,
    tlColor,
    tlZIndex,
    tlStyles,
    tlOpacity,
    tlPadding,
    tlFontSize,
    tlMaxWidth,
    tlMaxHeight,
    tlFontFamily,
    tlBorderRadius,
    tlBackgroundColor,
    tlPosition,
    tlOverflow,
    tlWhiteSpace,
    tlTextOverflow,
    bStyles,
    ...resSelect
}: PropsSelect | TSelect): ReactElement<PropsSelect | TSelect> => {
    // Initial variables
    const [errors, setErrors] = useState<boolean | undefined>(error)
    const [message, setMessage] = useState<any>('El campo no debe estar vacío')
    const [dataOptions, setDataOptions] = useState<any[]>([])
    const [valueInput, setValueInput] = useState<any>({})
    const [valuesInput, setValuesInput] = useState<any[]>([])
    const [vR] = useState(valRand())
    const [axis, setAxis] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisSelect, setAxisSelect] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisL, setAxisL] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisLabel, setAxisLabel] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const configs = InitConfigSelect.getStyles()

    useEffect(() => {
        const time = setTimeout(() => (!axis.x && !axis.y && axisSelect?.x && axisSelect?.y && (configs?.label?.activeTitle || sActiveTitle)) && setAxis({ x: Number(axisSelect?.x) + 20, y: Number(axisSelect?.y) + 20 }), 800)
        return () => clearTimeout(time)
    }, [axisSelect])

    useEffect(() => {
        const timeLabel = setTimeout(() => (!axisL.x && !axisL.y && axisLabel?.x && axisLabel?.y && (configs?.label?.activeTitle || lActiveTitle)) && setAxisL({ x: Number(axisLabel?.x) + 20, y: Number(axisLabel?.y) + 20 }), 800)
        return () => clearTimeout(timeLabel)
    }, [axisLabel])

    useEffect(() => {
        const inputElement = document.getElementById(`wow-ft-select-${vR}`)?.lastChild as Element
        if (inputElement) {
            dataIndex && inputElement.setAttribute('data-index', dataIndex)
            nameArray && inputElement.setAttribute('data-name-array', nameArray)
            dataCy && inputElement.setAttribute('data-cy', dataCy)
            required && inputElement.setAttribute('data-required', required)
            isDisabled && inputElement.setAttribute('data-disabled', String(isDisabled))
            dataObject && inputElement.setAttribute('data-object', dataObject)
        }
    }, [])

    useEffect(() => {
        setErrors(error)
    }, [error])

    // captura la respuesta
    useEffect(() => {
        if (data?.length !== dataOptions.length) {
            setDataOptions((data || []).map((x: any) => {
                // check for destructuring in combination
                if (nameCombineObject) {
                    return {
                        ...x,
                        name,
                        value: resolveData(x, undefined, undefined, String(nameId || name), undefined, nameCombineObject?.split('.')),
                        label: valueSelectCustom ? valueSelectCustom(x) : resolveData(x, undefined, undefined, String(nameValue), undefined, nameCombineObject?.split('.')),
                    }
                // check if there is destructuring in value or label
                } else if (nameValueObject || nameObject) {
                    return {
                        ...x,
                        name,
                        value: nameObject ? resolveData(x, undefined, undefined, String(nameId || name), undefined, nameObject?.split('.')) : x[String(nameId || name)],
                        label: valueSelectCustom ? valueSelectCustom(x) : (nameValueObject ? resolveData(x, undefined, undefined, String(nameValue), undefined, nameValueObject?.split('.')) : x[String(nameValue)]),
                    }
                }
                return {
                    ...x,
                    name,
                    value: x[String(nameId || name)],
                    label: valueSelectCustom ? valueSelectCustom(x) : x[String(nameValue)],
                }
            }))
        }
    }, [data])

    // captura el valor por defecto
    useEffect(() => {
        // verifica si es array
        if (Array.isArray(val)) {
            // verifica si es objeto
            const values = val.map(x => {
                if (typeof(x) === 'object') {
                    const findData = dataObject ? (data || []).find((y: any) => y[String(nameId || name)] === resolveData(x, Number(dataIndex), nameArray, String(nameId || name))) : x
                    // const findData = dataObject ? (data || []).find((y: any) => {
                    //     if (nameCombineObject) return resolveData(x, Number(dataIndex), nameArray, String(nameId || name), true, nameCombineObject?.split('.')) === resolveData(val, Number(dataIndex), nameArray, String(nameId || name), undefined, dataObject?.split('.'))
                    //     else if (nameObject) return resolveData(x, Number(dataIndex), nameArray, String(nameId || name), true, nameObject?.split('.')) === resolveData(val, Number(dataIndex), nameArray, String(nameId || name), undefined, dataObject?.split('.'))
                    //     return y[String(nameId || name)] === resolveData(val, Number(dataIndex), nameArray, String(nameId || name), undefined, dataObject?.split('.'))
                    // }) : x
                    return {
                        ...x,
                        name,
                        dataIndex,
                        nameArray,
                        value: findData?.[String(nameId || name)],
                        label: valueSelectCustom ? valueSelectCustom(findData || {}) : findData?.[String(nameValue)],
                    }

                // verifica si es numero o letra y que la data tenga registro
                } else if (data?.length) {
                    const findData = data.find((y: any) => y[String(nameId || name)] === (dataObject ? resolveData(val, Number(dataIndex), nameArray, String(nameId || name)) : val))
                    if (findData) {
                        return {
                            ...findData,
                            label: valueSelectCustom ? valueSelectCustom(findData) : findData[String(nameValue)],
                            name,
                            value: findData[String(nameId || name)],
                            dataIndex,
                            nameArray
                        }
                    }
                }
                return x
            })
            setValuesInput(values)

            // verifica si es objeto
        } else if (typeof(val) === 'object') {
            const findData = dataObject ? (data || []).find((x: any) => {
                if (nameCombineObject) return resolveData(x, undefined, undefined, String(nameId || name), undefined, nameCombineObject?.split('.')) === resolveData(val, Number(dataIndex), nameArray, String(nameId || name), undefined, dataObject?.split('.'))
                else if (nameObject) return resolveData(x, undefined, undefined, String(nameId || name), undefined, nameObject?.split('.')) === resolveData(val, Number(dataIndex), nameArray, String(nameId || name), undefined, dataObject?.split('.'))
                return x[String(nameId || name)] === resolveData(val, Number(dataIndex), nameArray, String(nameId || name), undefined, dataObject?.split('.'))
            }) : val

            if (nameCombineObject) {
                setValueInput({
                    ...findData,
                    name,
                    dataIndex,
                    nameArray,
                    value: resolveData(findData, undefined, undefined, String(nameId || name), undefined, nameCombineObject?.split('.')),
                    label: valueSelectCustom ? valueSelectCustom(findData) : resolveData(findData, undefined, undefined, String(nameValue), undefined, nameCombineObject?.split('.')),
                })
            } else if (nameObject || nameValueObject) {
                setValueInput({
                    ...findData,
                    name,
                    dataIndex,
                    nameArray,
                    value: nameObject ? resolveData(findData, undefined, undefined, String(nameId || name), undefined, nameObject?.split('.')) : findData[String(nameId || name)],
                    label: valueSelectCustom ? valueSelectCustom(findData) : (nameValueObject ? resolveData(findData, undefined, undefined, String(nameValue), undefined, nameValueObject?.split('.')) : findData[String(nameValue)]),
                })
            } else {
                setValueInput({
                    ...val,
                    name,
                    dataIndex,
                    nameArray,
                    value: findData?.[String(nameId || name)],
                    label: valueSelectCustom ? valueSelectCustom(findData || {}) : findData?.[String(nameValue)],
                })
            }

            // verifica si es numero o letra y que la data tenga registro
        } else if (data?.length) {
            const findData = data?.find((x: any) => {
                if (nameCombineObject) return resolveData(x, undefined, undefined, String(nameId || name), undefined, nameCombineObject?.split('.')) === val
                else if (nameObject) return resolveData(x, undefined, undefined, String(nameId || name), undefined, nameObject?.split('.')) === val
                return x[String(nameId || name)] === val
            })

            if (findData) {
                if (nameCombineObject) {
                    setValueInput({
                        ...findData,
                        name,
                        dataIndex,
                        nameArray,
                        value: resolveData(findData, undefined, undefined, String(nameId || name), undefined, nameCombineObject?.split('.')),
                        label: valueSelectCustom ? valueSelectCustom(findData) : resolveData(findData, undefined, undefined, String(nameValue), undefined, nameCombineObject?.split('.')),
                    })
                } else if (nameObject || nameValueObject) {
                    setValueInput({
                        ...findData,
                        name,
                        dataIndex,
                        nameArray,
                        value: nameObject ? resolveData(findData, undefined, undefined, String(nameId || name), undefined, nameObject?.split('.')) : findData[String(nameId || name)],
                        label: valueSelectCustom ? valueSelectCustom(findData) : (nameValueObject ? resolveData(findData, undefined, undefined, String(nameValue), undefined, nameValueObject?.split('.')) : findData[String(nameValue)]),
                    })
                } else {
                    setValueInput({
                        ...findData,
                        name,
                        dataIndex,
                        nameArray,
                        value: findData[String(nameId || name)],
                        label: valueSelectCustom ? valueSelectCustom(findData) : findData[String(nameValue)],
                    })
                }
            } else setValueInput({ label: '', name, value: '', dataIndex, nameArray })
        }
    }, [val, dataOptions])

    /**
     * @description What function to validate the text fields by the onSelect method
     * @version 0.0.1
     * @param {object} v values select
     * @param {object} actionMeta actions select
     * @return {void}
     *
    */
    const handleChange = (v: ValueType<any, any>, actionMeta: ActionMeta<any>): void | undefined => {
        let newValue = v
        let valError = true

        // obtiene la acción de limpiar el campo
        if (actionMeta?.action === 'clear') newValue = { ...newValue, value: undefined }

        // Validate that the field is not null
        if (required && validation) {
            if (isNull(newValue.value)) {
                valError = false
                setMessage(errorMessageRequired || configs.messageError?.required || '')
            }
        }

        onSelect({
            name,
            actionSelect: actionMeta,
            required,
            nameArray,
            dataExtra,
            value: newValue.value,
            error: !valError,
            index: dataIndex,
            nameObject: dataObject,
            ...(rowData ? v : {})
        })
    }

    return (
        <Container
            gs={configs}
            cStyles={cStyles}
            cMargin={cMargin}
            cDisplay={cDisplay}
            cMinWidth={cMinWidth}
            cMaxWidth={cMaxWidth}
            cPosition={cPosition}
            widthTable={widthTable}
            widthPhone={widthPhone}
            mediaTable={mediaTable}
            mediaPhone={mediaPhone}
            cWidth={cWidth || '100%'}
            maxWidthTable={maxWidthTable}
            maxWidthPhone={maxWidthPhone}
            cFlexDirection={cFlexDirection}
            className='wow-ft-select-container'
            cHoverDisplayTooltip={cHoverDisplayTooltip}
            onClick={!isLoading ? onClick : () => null}
        >
            {!lNotValue &&
                <LabelSelect
                    val={val}
                    gs={configs}
                    axis={axisL}
                    error={errors}
                    lColor={lColor}
                    lBorder={lBorder}
                    lMargin={lMargin}
                    lStyles={lStyles}
                    lPadding={lPadding}
                    lFontSize={lFontSize}
                    lOverflow={lOverflow}
                    lTextAlign={lTextAlign}
                    lWhiteSpace={lWhiteSpace}
                    lFontFamily={lFontFamily}
                    lErrorColor={lErrorColor}
                    valLabel={lTitle || title}
                    lTextOverflow={lTextOverflow}
                    className='wow-ft-select-label'
                    lNotValueColor={lNotValueColor}
                    lHoverDisplayTooltip={lHoverDisplayTooltip}
                    lActiveTitle={configs?.label?.activeTitle || lActiveTitle}
                    tlColor={tlColor}
                    tlZIndex={tlZIndex}
                    tlStyles={tlStyles}
                    tlOpacity={tlOpacity}
                    tlPadding={tlPadding}
                    tlFontSize={tlFontSize}
                    tlMaxWidth={tlMaxWidth}
                    tlPosition={tlPosition}
                    tlOverflow={tlOverflow}
                    tlMaxHeight={tlMaxHeight}
                    tlFontFamily={tlFontFamily}
                    tlWhiteSpace={tlWhiteSpace}
                    tlTextOverflow={tlTextOverflow}
                    tlBorderRadius={tlBorderRadius}
                    tlBackgroundColor={tlBackgroundColor}
                    onPointerMove={e => (configs?.label?.activeTitle || lActiveTitle) && setAxisLabel({ x: e.clientX, y: e.clientY })}
                    onPointerLeave={() => { if (axisL.x || axisL.y && (configs?.label?.activeTitle || lActiveTitle)) { setAxis({}); setAxisSelect({}); setAxisL({}); setAxisLabel({}) } }}
                >
                    {title}
                </LabelSelect>
            }
            <BoxSelect
                axis={axis}
                gs={configs}
                bStyles={bStyles}
                tiColor={tiColor}
                tiZIndex={tiZIndex}
                tiStyles={tiStyles}
                tiOpacity={tiOpacity}
                tiPadding={tiPadding}
                tiFontSize={tiFontSize}
                tiMaxWidth={tiMaxWidth}
                tiPosition={tiPosition}
                tiOverflow={tiOverflow}
                tiMaxHeight={tiMaxHeight}
                tiVisibility={tiVisibility}
                tiFontFamily={tiFontFamily}
                tiWhiteSpace={tiWhiteSpace}
                tiTextOverflow={tiTextOverflow}
                tiBorderRadius={tiBorderRadius}
                val={sTitle || valueInput?.label}
                className='wow-ft-select-box-select'
                tiBackgroundColor={tiBackgroundColor}
                sActiveTitle={configs?.select?.activeTitle || sActiveTitle}
                onPointerMove={e => (configs?.select?.activeTitle || sActiveTitle) && setAxisSelect({ x: e.clientX, y: e.clientY })}
                onPointerLeave={() => { if (axis.x || axis.y && (configs?.select?.activeTitle || sActiveTitle)) { setAxis({}); setAxisSelect({}); setAxisL({}); setAxisLabel({}) } }}
            >
                <ReactSelect
                    name={name}
                    id={`wow-ft-select-${vR}`}
                    options={dataOptions}
                    components={{
                        LoadingIndicator: function loading() {
                            return (
                                (loadingContainer || configs.loading?.container) ||
                                <Loading
                                    size={sizeLoading || '20px'}
                                    right={rightLoading || '35px'}
                                    bottom={bottomLoading || '0'}
                                    color={colorLoading || configs.loading?.color}
                                    left={configs.loading?.left}
                                    position={configs.loading?.position}
                                    top={configs.loading?.top}
                                />
                            )
                        }
                    }}
                    data-cy={dataCy}
                    autoFocus={focus}
                    data-index={dataIndex}
                    data-name-array={nameArray}
                    value={valuesInput?.length ? valuesInput : (valueInput?.label ? valueInput : '')}
                    data-required={required}
                    data-disabled={isDisabled}
                    isDisabled={isDisabled}
                    data-object={dataObject}
                    isLoading={isLoading}
                    theme={{ ...configs.theme, ...theme }}
                    styles={{
                        ...configs.select?.styles,
                        ...sStyles,

                        control: (provided, state) => {
                            let border = ''
                            if (configs.select?.styles?.control) border = String(configs.select.styles.control(provided, state as any).border)
                            else if (sStyles?.control) border = sStyles.control(provided, state).border
                            else border = `1px solid ${Colors.select?.selectBorder}`

                            return {
                                ...provided,
                                ...(((configs?.select?.activeTitle || sActiveTitle) && (sTitle || valueInput?.label) && axis?.y && axis.x) ? {
                                } : {}),
                                ...(sTransform ? { textTransform: sTransform } : {}),
                                ...(configs.select?.styles?.control ? configs.select.styles.control(provided, state as any) : {}),
                                ...(sStyles?.control ? sStyles.control(provided, state) : {}),
                                border: error ? `1px solid ${Colors.select?.selectBorderError}` : border,
                            }
                        },
                        indicatorSeparator: (provided, state) => {
                            let backgroundColor = ''
                            if (configs.select?.styles?.indicatorsContainer) backgroundColor = String(configs.select.styles.indicatorsContainer(provided, state as any).backgroundColor)
                            else if (sStyles?.indicatorsContainer) backgroundColor = sStyles.indicatorsContainer(provided, state).backgroundColor
                            else backgroundColor = String(Colors.select?.selectBorder)

                            return {
                                ...provided,
                                ...(configs.select?.styles?.indicatorsContainer ? configs.select.styles.indicatorsContainer(provided, state as any) : {}),
                                ...(sStyles?.indicatorsContainer ? sStyles.indicatorsContainer(provided, state) : {}),
                                backgroundColor: error ? Colors.select?.selectBorderError : backgroundColor
                            }
                        },
                        dropdownIndicator: (provided, state) => {
                            let color = ''
                            if (configs.select?.styles?.indicatorsContainer) color = String(configs.select.styles.indicatorsContainer(provided, state as any).color)
                            else if (sStyles?.indicatorsContainer) color = sStyles.indicatorsContainer(provided, state).color
                            else color = String(Colors.select?.selectBorder)

                            return {
                                ...provided,
                                ...(configs.select?.styles?.indicatorsContainer ? configs.select.styles.indicatorsContainer(provided, state as any) : {}),
                                ...(sStyles?.indicatorsContainer ? sStyles.indicatorsContainer(provided, state) : {}),
                                color: error ? Colors.select?.selectBorderError : color
                            }
                        },
                        clearIndicator: (provided, state) => {
                            let color = ''
                            if (configs.select?.styles?.clearIndicator) color = String(configs.select.styles.clearIndicator(provided, state as any).color)
                            else if (sStyles?.clearIndicator) color = sStyles.clearIndicator(provided, state).color
                            else color = String(Colors.select?.selectBorder)

                            return {
                                ...provided,
                                ...(configs.select?.styles?.clearIndicator ? configs.select.styles.clearIndicator(provided, state as any) : {}),
                                ...(sStyles?.clearIndicator ? sStyles.clearIndicator(provided, state) : {}),
                                color: error ? Colors.select?.selectBorderError : color
                            }
                        },
                        menu: (provided, state) => {
                            return {
                                ...provided,
                                ...(sTransformMenu ? { textTransform: sTransformMenu } : {}),
                                ...(configs.select?.styles?.menu ? configs.select.styles.menu(provided, state as any) : {}),
                                ...(sStyles?.menu ? sStyles.menu(provided, state) : {}),
                            }
                        },
                    }}
                    {...resSelect}
                    onChange={handleChange}
                >
                </ReactSelect>
            </BoxSelect>
            {errors &&
                <Tooltip
                    tTop={tTop}
                    tLeft={tLeft}
                    tColor={tColor}
                    tZIndex={tZIndex}
                    tBorder={tBorder}
                    tBottom={tBottom}
                    tStyles={tStyles}
                    tDisplay={tDisplay}
                    tPadding={tPadding}
                    tPosition={tPosition}
                    tFontSize={tFontSize}
                    tBoxShadow={tBoxShadow}
                    tFontFamily={tFontFamily}
                    tBorderRadius={tBorderRadius}
                    className='wow-ft-select-tooltip'
                    gs={configs}
                    tTopAfterBefore={tTopAfterBefore}
                    tLeftAfterBefore={tLeftAfterBefore}
                    tBackgroundColor={tBackgroundColor}
                    tBorderAfterBefore={tBorderAfterBefore}
                    tBottomAfterBefore={tBottomAfterBefore}
                    tContentAfterBefore={tContentAfterBefore}
                    tPositionAfterBefore={tPositionAfterBefore}
                    tPointerEventsAfterBefore={tPointerEventsAfterBefore}
                >
                    {message}
                </Tooltip>}
        </Container>
    )
}