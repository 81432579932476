/**
 * @description Class containing the initial configuration Colors
 * @author ConfigInitTable?
 * @version 1.0.0
 */
'use strict';

// Declare type
export declare type TDefaultColor = {
    input?: {
        label?: string
        input?: string
        symbol?: string
        primary?: string
        labelLight?: string
        labelHight?: string
        labelError?: string
        inputHight?: string
        placeholder?: string
        inputBorder?: string
        symbolHight?: string
        tooltipColor?: string
        tooltipBorder?: string
        inputBorderError?: string
        inputBorderSuccess?: string
        inputBackgroundColor?: string
        tooltipColorBoxShadow?: string
        tooltipBackgroundColor?: string
        inputBackgroundColorDisabled?: string
        inputBackgroundColorDisabledClick?: string
    },
    select?: {
        label?: string
        danger?: string
        select?: string
        symbol?: string
        primary?: string
        labelLight?: string
        labelHight?: string
        labelError?: string
        neutral10?: string
        neutral20?: string
        neutral30?: string
        neutral40?: string
        neutral50?: string
        neutral60?: string
        neutral70?: string
        neutral80?: string
        neutral90?: string
        dangerLight?: string
        selectHight?: string
        symbolHight?: string
        tooltipColor?: string
        selectBorder?: string
        tooltipBorder?: string
        selectBorderError?: string
        tooltipColorBoxShadow?: string
        selectBackgroundColor?: string
        tooltipBackgroundColor?: string
        selectBackgroundColorDisabled?: string
    }
    loading?: {
        color?: string
    }
    textarea?: {
        label?: string
        textarea?: string
        symbol?: string
        primary?: string
        labelLight?: string
        labelHight?: string
        labelError?: string
        textareaHight?: string
        placeholder?: string
        textareaBorder?: string
        symbolHight?: string
        tooltipColor?: string
        tooltipBorder?: string
        textareaBorderError?: string
        textareaBorderSuccess?: string
        textareaBackgroundColor?: string
        tooltipColorBoxShadow?: string
        tooltipBackgroundColor?: string
        textareaBackgroundColorDisabled?: string
        textareaBackgroundColorDisabledClick?: string
    },
}

const defaultColor: TDefaultColor = {
    input: {
        primary: '#009587',
        placeholder: '#B5B4B7',
        label: '#393939',
        labelLight: '#717171',
        labelHight: '#222222',
        labelError: '#E40101',
        inputBorder: '#DDDDDD',
        inputBorderError: '#e81b1b',
        inputBorderSuccess: '#DDDDDD',
        input: '#393939',
        inputHight: '#222222',
        inputBackgroundColor: '#FFFFFF',
        inputBackgroundColorDisabled: '#fafafa',
        inputBackgroundColorDisabledClick: '#fafafa',
        symbol: '#393939',
        symbolHight: '#222222',
        tooltipColor: '#E40101',
        tooltipBorder: '#DDDDDD',
        tooltipBackgroundColor: '#FFFFFF',
        tooltipColorBoxShadow: '#75757555'
    },
    select: {
        label: '#393939',
        danger: '#e81b1b',
        select: '#393939',
        symbol: '#393939',
        primary: '#009587',
        neutral10: '#DDDDDD',
        neutral20: '#CCCCCC',
        neutral30: '#999999',
        neutral40: '#393939',
        neutral50: '#B5B4B7',
        neutral60: '#555555',
        neutral70: '#444444',
        neutral80: '#333333',
        neutral90: '#393939',
        labelLight: '#717171',
        labelHight: '#222222',
        labelError: '#E40101',
        dangerLight: '#E40101',
        selectHight: '#222222',
        symbolHight: '#222222',
        selectBorder: '#DDDDDD',
        tooltipColor: '#E40101',
        tooltipBorder: '#DDDDDD',
        selectBorderError: '#e81b1b',
        selectBackgroundColor: '#FFFFFF',
        tooltipBackgroundColor: '#FFFFFF',
        tooltipColorBoxShadow: '#75757555',
        selectBackgroundColorDisabled: '#FAFAFA',
    },
    loading: {
        color: '#009522'
    },
    textarea: {
        primary: '#009587',
        placeholder: '#B5B4B7',
        label: '#393939',
        labelLight: '#717171',
        labelHight: '#222222',
        labelError: '#E40101',
        textareaBorder: '#DDDDDD',
        textareaBorderError: '#e81b1b',
        textareaBorderSuccess: '#DDDDDD',
        textarea: '#393939',
        textareaHight: '#222222',
        textareaBackgroundColor: '#FFFFFF',
        textareaBackgroundColorDisabled: '#fafafa',
        textareaBackgroundColorDisabledClick: '#fafafa',
        symbol: '#393939',
        symbolHight: '#222222',
        tooltipColor: '#E40101',
        tooltipBorder: '#DDDDDD',
        tooltipBackgroundColor: '#FFFFFF',
        tooltipColorBoxShadow: '#75757555'
    },
}

// Config init colors
export class InitConfigColor {
    private static defaultColors: TDefaultColor = defaultColor

    // Getters
    static getColors(): TDefaultColor {
        return this.defaultColors
    }

    // Setter
    static configColors(newStyles: TDefaultColor): void {
        this.defaultColors = {
            input: {
                ...this.defaultColors.input,
                ...(newStyles?.input || {})
            },
            select: {
                ...this.defaultColors.select,
                ...(newStyles?.select || {})
            },
            loading: {
                ...this.defaultColors.loading,
                ...(newStyles?.loading || {})
            },
            textarea: {
                ...this.defaultColors.textarea,
                ...(newStyles?.textarea || {})
            },
        }
    }
}