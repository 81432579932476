import React, { FC, ReactElement } from 'react'
import PropTypes from 'prop-types'
import { Container, Circle } from './styled'
import { TLoading } from './types'
import { InitConfigColor } from '../../utils/colors'
const Colors = InitConfigColor.getColors()

export const Loading: FC<TLoading> = ({ size, top, bottom, left, right, position, color }: TLoading): ReactElement<TLoading> => (
    <Container
        size={size}
        top={top}
        bottom={bottom}
        right={right}
        left={left}
        position={position}
    >
        <svg viewBox='0 0 100 100'>
            <defs>
                <filter id='shadow'>
                    <feDropShadow dx='0' dy='0' stdDeviation='1.5' floodColor={color || Colors.loading?.color} />
                </filter>
            </defs>
            <Circle
                fill='transparent'
                stroke={color || Colors.loading?.color}
                strokeWidth='7px'
                strokeLinecap='round'
                filter='url(#shadow)'
                cx='50'
                cy='50'
                r='45'
            />
        </svg>
    </Container>
)

Loading.propTypes = {
    size: PropTypes.string,
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    position: PropTypes.string
}