/* eslint-disable @typescript-eslint/no-explicit-any */
import { TOnChangeInput } from 'index'
import { useCallback, useEffect, useState } from 'react'
import { updateData, validationSubmitHooks } from '../../utils/index'
import { IArgsSubmit, IResponseHooks } from './types'

/**
 * @version 0.0.1
 * @description Hook con herramientas de validación y eventos de cambio
 * @param {any} initState Valor inicial del hook
 * @return {Array} devuelve la función onChange a ejecutar y el estado de error de cada input
 */
export const useFormTools = <T>(initState?: T): IResponseHooks<T> => {
    // States and Variables
    const [dataForm, setDataForm] = useState<T | any>(initState || {})
    const [errorForm, setErrorForm] = useState<T | any>({})
    const [errorSubmit, setErrorSubmit] = useState<boolean>(false)
    const [calledSubmit, setCalledSubmit] = useState<boolean>(false)

    /**
     * Handle change
     * ```ts
     * @param {TOnChangeInput} dv Data values
     * @returns {void}
     * ```
    */
    const handleChange = useCallback((dv: TOnChangeInput) => {
        // Variables
        const { name, value, error, nameArray, index, nameObject } = dv
        let resData = dataForm
        let resError = errorForm

        // Valida que sea un array o un objeto para crear la estructura de datos
        if (nameObject) {
            resData = updateData(nameObject.split('.'), 0, dataForm, index as number, nameArray as string, name, value)
            resError = updateData(nameObject.split('.'), 0, errorForm, index as number, nameArray as string, name, error)
        }
        else {
            resData = { ...resData, [name]: value }
            resError = { ...errorForm, [name]: error }
        }

        // Actualiza los estados locales de data y error
        setDataForm(resData)
        setErrorForm(resError)

    }, [setDataForm, dataForm, errorForm, setErrorForm])

    /**
     *  Forzar la actualización de los datos locales
     * @param {object} data Nuevos datos a insertar
     * @returns {void}
    */
    const setForcedData = useCallback((data: T) => setDataForm(data), [setDataForm])

    // Forzar datos de error desde una ventana externa
    const setForcedError = useCallback((errors: T) => {
        setErrorForm(errors)
    }, [setErrorForm])

    /**
     * Función que se ejecuta al enviar el formulario
     * @param {{
     *          event: object,
     *          action: function,
     *          actionOnError: function,
     *          actionOfSuccess: function
     * }} data Parámetros o acciones a ejecutar
    */
    const handleSubmit = useCallback(({ event, action, actionOnError, actionOfSuccess, validationOnError }: IArgsSubmit) => {
        !!event && event.preventDefault()
        setCalledSubmit(true)
        let errSub = false

        // Valida los errores desde el evento
        const { errorForm: errors, isError } = validationSubmitHooks(event?.target?.elements)
        setErrorForm(errors)
        errSub = isError

        // Ejecuta la petición si es válido
        if (!errSub && !!action) {
            action().then((res: any) => {
                actionOfSuccess && actionOfSuccess(res)
            }).catch((e: any) => !!actionOnError && actionOnError(e))
        } else {
            setErrorSubmit(errSub)
            if (validationOnError && errSub) validationOnError()
        }

    }, [errorForm, setErrorForm])

    useEffect(() => setCalledSubmit(false), [calledSubmit])

    return [handleChange, handleSubmit, { dataForm, errorForm, errorSubmit, calledSubmit, setForcedError, setForcedData }]
}