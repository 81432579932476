/* eslint-disable @typescript-eslint/no-explicit-any */
// Modules
import React, { useEffect, useState, FC, ChangeEvent, ReactElement } from 'react'

// Components
import { Loading } from '../Loading'

// Utils, Styles and Types
import { isEmail, isNull, isNumeric, onlyLetters, passwordFormat, rangeLength, numberFormat, dateFormat, hourFormat, phoneFormat, resolveData } from '../../utils/index';
import { ContainerInput, InputV, LabelInput, Tooltip, SymbolSpan, BoxInput } from './styled'
import { InitConfigInput, TDefaultInputStyle } from './settings'
import { TInput } from './types'

/**
 * Input components
 * @version 0.0.1
 * ```ts
 * @param {TInput} props properties Input
 * @return {ReactElement<TInput>} node Input
 * ```
 */
export const Input: FC<TInput> = ({
    name,
    form,
    type,
    pass,
    date,
    hour,
    click,
    value,
    title,
    email,
    error,
    range,
    focus,
    float,
    symbol,
    dataCy,
    format,
    onBlur,
    numeric,
    equalTo,
    minimum,
    maximum,
    onClick,
    letters,
    disabled,
    onChange,
    required,
    dataIndex,
    reference,
    nameArray,
    isLoading,
    dataExtra,
    formatDate,
    widthTable,
    widthPhone,
    mediaTable,
    mediaPhone,
    dataObject,
    formatHour,
    typeNumeric,
    placeholder,
    formatPhone,
    autoComplete,
    maxWidthTable,
    maxWidthPhone,
    validation = true,
    cWidth,
    cMargin,
    cStyles,
    cDisplay,
    cMaxWidth,
    cMinWidth,
    cPosition,
    cFlexDirection,
    cHoverDisplayTooltip,
    lTitle,
    lStyles,
    lBorder,
    lMargin,
    lPadding,
    lOverflow,
    lNotValue,
    lFontSize,
    lTextAlign,
    lWhiteSpace,
    lFontFamily,
    lErrorColor,
    lTextOverflow,
    lNotValueColor,
    lActiveTitle,
    lHoverDisplayTooltip,
    lColor,
    iColor,
    iTitle,
    iWidth,
    iBorder,
    iStyles,
    iPadding,
    iOutline,
    iFontSize,
    iTextAlign,
    iTransform,
    iMinHeight,
    iFontFamily,
    iAlt = true,
    iBorderError,
    iBorderFocus,
    iBorderRadius,
    iBorderSuccess,
    iColorDisabled,
    iCursorDisabled,
    iBackgroundColor,
    iActiveTitle,
    iFontFamilyDisabled,
    iColorDisabledClick,
    iHoverDisplayTooltip,
    iCursorDisabledClick,
    iFontFamilyDisabledClick,
    iBackgroundColorDisabled,
    iBackgroundColorDisabledClick,
    sLeft,
    sColor,
    sBottom,
    sStyles,
    sFontSize,
    sPosition,
    sFontFamily,
    sColorDisabled,
    sColorDisabledClick,
    sFontFamilyDisabled,
    sFontFamilyDisabledClick,
    tTop,
    tLeft,
    tColor,
    tZIndex,
    tBorder,
    tBottom,
    tPadding,
    tFontSize,
    tBoxShadow,
    tFontFamily,
    tBorderRadius,
    tTopAfterBefore,
    tLeftAfterBefore,
    tBackgroundColor,
    tBorderAfterBefore,
    tBottomAfterBefore,
    tContentAfterBefore,
    tDisplay,
    tPosition,
    tStyles,
    tPositionAfterBefore,
    tPointerEventsAfterBefore,
    sizeLoading,
    rightLoading,
    colorLoading,
    bottomLoading,
    loadingContainer,
    errorMessagePass,
    errorMessageEmail,
    errorMessageLetter,
    errorMessageNumeric,
    errorMessageRequired,
    errorMessageDate,
    errorMessageHour,
    errorMessageMinimum,
    errorMessageMaximum,
    errorMessageRange,
    tiColor,
    tiZIndex,
    tiStyles,
    tiOpacity,
    tiPadding,
    tiFontSize,
    tiMaxWidth,
    tiMaxHeight,
    tiFontFamily,
    tiBorderRadius,
    tiBackgroundColor,
    tiPosition,
    tiOverflow,
    tiWhiteSpace,
    tiTextOverflow,
    tlColor,
    tlZIndex,
    tlStyles,
    tlOpacity,
    tlPadding,
    tlFontSize,
    tlMaxWidth,
    tlMaxHeight,
    tlFontFamily,
    tlBorderRadius,
    tlBackgroundColor,
    tlPosition,
    tlOverflow,
    tlWhiteSpace,
    tlTextOverflow,
    bStyles,
}: TInput): ReactElement<TInput> => {
    // Initial variables
    const [errors, setErrors] = useState<boolean | undefined>(error)
    const [axis, setAxis] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisInput, setAxisInput] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisL, setAxisL] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisLabel, setAxisLabel] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [message, setMessage] = useState<string>('El campo no debe estar vacío')
    const [configs] = useState<TDefaultInputStyle>(InitConfigInput.getStyles())

    useEffect(() => {
        setErrors(error)
    }, [error])

    // useEffect(() => {
    // }, [value])

    useEffect(() => {
        let timeInput = setTimeout(() => undefined)
        if (!axis.x && !axis.y && axisInput?.x && axisInput?.y && (configs?.input?.activeTitle || iActiveTitle)) {
            timeInput = setTimeout(() => setAxis({ x: Number(axisInput?.x) + 10, y: Number(axisInput?.y) + 15 }), 800)
        }
        return () => clearTimeout(timeInput)
    }, [axisInput])

    useEffect(() => {
        let timeLabel = setTimeout(() => undefined)
        if (!axisL.x && !axisL.y && axisLabel?.x && axisLabel?.y && (configs?.label?.activeTitle || lActiveTitle)) {
            timeLabel = setTimeout(() => setAxisL({ x: Number(axisLabel?.x) + 10, y: Number(axisLabel?.y) + 15 }), 800)
        }
        return () => clearTimeout(timeLabel)
    }, [axisLabel])

    /**
     * @description What function to validate the text fields by the onChange method
     * @version 0.0.1
     * @param {object} e change method event
     * @return {void}
     *
    */
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let valError = true
        let newValue: number | string = e?.target?.value

        // Check if it is not a format or number
        if (!format && !typeNumeric && validation) newValue = `${newValue}`
        else if (format) newValue = `${e?.target?.value}`.replace(/\./g, '')

        // Check if a number
        if (typeNumeric && newValue && validation) {
            const newNumber = float ? e?.target?.value : `${e?.target?.value}`.replace(/\./g, '')
            newValue = float ? parseFloat(newValue) : parseInt(newNumber)
        }

        // Validate if the field has a correct password format
        if (pass && validation) {
            if (e.target.value?.length > 0 && passwordFormat(e.target.value)) {
                valError = false
                setMessage(errorMessagePass || configs.messageError?.pass || '')
            }
        }

        // Validate that the field is not null
        if (required && validation) {
            if (isNull(newValue)) {
                valError = false
                setMessage(errorMessageRequired || configs.messageError?.required || '')
            }
        }
        // Validate that the field is a numeric type
        if (numeric && validation) {
            if (isNumeric(newValue) && !!newValue){
                valError = false
                setMessage(errorMessageNumeric || configs.messageError?.numeric || '')
            }
        }
        // Validate that the field is only letters
        if (letters && validation) {
            if (onlyLetters(String(newValue))) {
                valError = false
                setMessage(errorMessageLetter || configs.messageError?.letter || '')
            }
        }
        // Validate that the field is in the correct range
        if (!!String(newValue).length && !!range && validation) {
            if (rangeLength(newValue, range[0], range[1])) {
                valError = false
                setMessage(errorMessageRange ? errorMessageRange(range[0], range[1]) : configs.messageError?.range && configs.messageError?.range(range[0], range[1]) || '')
            }
        }
        // Validate if the field has a correct email format
        if (email && newValue && validation) {
            if (isEmail(String(newValue))) {
                valError = false
                setMessage(errorMessageEmail || configs.messageError?.email || '')
            }
        }
        // Validate that it is equal to another value
        if (equalTo && equalTo?.value && validation) {
            if (newValue !== equalTo?.value) {
                valError = false
                setMessage(equalTo?.errorMessage)
            }
        }

        // Validate that the value is greater than the minimum number
        if (!isNaN(Number(minimum)) && newValue && validation) {
            if (newValue < Number(minimum)) {
                valError = false
                setMessage(errorMessageMinimum ? errorMessageMinimum(minimum || 0) : configs.messageError?.minimum && configs.messageError?.minimum(minimum || 0) || '')
            }
        }

        // Validate that the value is less than the maximum number
        if (!isNaN(Number(maximum)) && newValue && validation) {
            if (newValue > Number(maximum)) {
                valError = false
                setMessage(errorMessageMaximum ? errorMessageMaximum(maximum || 0) : configs.messageError?.maximum && configs.messageError?.maximum(maximum || 0) || '')
            }
        }
        // Valid if it is a validity date
        if (date && newValue && validation) {
            const dateValue = new Date(newValue)
            if (isNaN(dateValue.getTime()) || Number(dateValue.getFullYear()) > 2050) {
                valError = false
                setMessage(errorMessageDate ? errorMessageDate(String(newValue)) : configs.messageError?.date && configs.messageError?.date(String(newValue)) || '')
            }
        }

        // Validate if the time is valid
        if (hour && newValue && validation) {
            const dateValue = new Date(newValue)
            if (isNaN(dateValue.getTime())) {
                valError = false
                setMessage(errorMessageHour ? errorMessageHour(String(newValue)) : configs.messageError?.hour && configs.messageError?.hour(String(newValue)) || '')
            }
        }

        valError && setMessage('')
        setErrors(!valError)
        onChange && onChange({
            name: String(name),
            hour,
            date,
            pass,
            email,
            range,
            format,
            minimum,
            maximum,
            equalTo,
            letters,
            numeric,
            required,
            event: e,
            nameArray,
            dataExtra,
            typeNumeric,
            value: newValue,
            error: !valError,
            index: dataIndex,
            nameObject: dataObject,
        })
    }

    /**
     * check if the data type of the input
     * @param {any} param param
     * @return {any} new value
     */
    const getValue = (param: any): any => {
        if (axisL.x || axisL.y) setAxisL({})
        if (axis.x || axis.y) setAxis({})
        if (dataObject && typeof param === 'object') {
            const resData = resolveData(param, Number(dataIndex), nameArray, String(name), false, dataObject?.split('.'))
            return resData
        } else {
            if (typeof param === 'object') return transformValue(param?.[String(name)])
            return transformValue(param)
        }
    }

    /**
     * return value
     * @param {any} param param
     * @return {any} new value format
     */
    const transformValue = (param: any): any => {
        if (!format && !formatDate && !formatPhone && !formatHour) return param || ''
        else if (format) return numberFormat(param)
        else if (formatDate) return dateFormat(param)
        else if (formatPhone) return phoneFormat(param)
        else if (formatHour) return hourFormat(param)
    }

    return (
        <ContainerInput
            gs={configs}
            cMargin={cMargin}
            cDisplay={cDisplay}
            cMinWidth={cMinWidth}
            cMaxWidth={cMaxWidth}
            cPosition={cPosition}
            widthTable={widthTable}
            widthPhone={widthPhone}
            mediaTable={mediaTable}
            mediaPhone={mediaPhone}
            cWidth={cWidth || '100%'}
            maxWidthTable={maxWidthTable}
            maxWidthPhone={maxWidthPhone}
            cFlexDirection={cFlexDirection}
            className='wow-ft-input-container'
            cHoverDisplayTooltip={cHoverDisplayTooltip}
            onClick={!isLoading ? onClick : () => null}
            cStyles={cStyles}
        >
            {!lNotValue &&
                <LabelInput
                    axis={axisL}
                    gs={configs}
                    error={errors}
                    lColor={lColor}
                    val={getValue(value)}
                    lBorder={lBorder}
                    lStyles={lStyles}
                    lMargin={lMargin}
                    lPadding={lPadding}
                    lOverflow={lOverflow}
                    lFontSize={lFontSize}
                    lTextAlign={lTextAlign}
                    lWhiteSpace={lWhiteSpace}
                    lFontFamily={lFontFamily}
                    lErrorColor={lErrorColor}
                    valLabel={lTitle || title}
                    lTextOverflow={lTextOverflow}
                    className='wow-ft-input-label'
                    lNotValueColor={lNotValueColor}
                    lHoverDisplayTooltip={lHoverDisplayTooltip}
                    lActiveTitle={configs?.label?.activeTitle || lActiveTitle}
                    onPointerMove={e => (configs?.label?.activeTitle || lActiveTitle) && setAxisLabel({ x: e.clientX, y: e.clientY })}
                    onPointerLeave={() => { if (axisL.x || axisL.y && (configs?.label?.activeTitle || lActiveTitle)) { setAxis({}); setAxisInput({}); setAxisL({}); setAxisLabel({}) } }}
                    tlColor={tlColor}
                    tlZIndex={tlZIndex}
                    tlStyles={tlStyles}
                    tlOpacity={tlOpacity}
                    tlPadding={tlPadding}
                    tlFontSize={tlFontSize}
                    tlMaxWidth={tlMaxWidth}
                    tlPosition={tlPosition}
                    tlOverflow={tlOverflow}
                    tlMaxHeight={tlMaxHeight}
                    tlFontFamily={tlFontFamily}
                    tlWhiteSpace={tlWhiteSpace}
                    tlTextOverflow={tlTextOverflow}
                    tlBorderRadius={tlBorderRadius}
                    tlBackgroundColor={tlBackgroundColor}
                >
                    {title}
                </LabelInput>
            }
            <BoxInput
                axis={axis}
                gs={configs}
                bStyles={bStyles}
                tiColor={tiColor}
                tiZIndex={tiZIndex}
                tiStyles={tiStyles}
                tiOpacity={tiOpacity}
                tiPadding={tiPadding}
                tiFontSize={tiFontSize}
                tiMaxWidth={tiMaxWidth}
                tiPosition={tiPosition}
                tiOverflow={tiOverflow}
                tiMaxHeight={tiMaxHeight}
                val={iTitle || getValue(value)}
                tiFontFamily={tiFontFamily}
                tiWhiteSpace={tiWhiteSpace}
                tiTextOverflow={tiTextOverflow}
                tiBorderRadius={tiBorderRadius}
                className='wow-ft-select-box-input'
                tiBackgroundColor={tiBackgroundColor}
                iActiveTitle={configs?.input?.activeTitle || iActiveTitle}
            >
                <InputV
                    name={name}
                    form={form}
                    axis={axis}
                    gs={configs}
                    click={click}
                    iColor={iColor}
                    iWidth={iWidth}
                    error={errors}
                    ref={reference}
                    onBlur={onBlur}
                    data-pass={pass}
                    data-date={date}
                    data-hour={hour}
                    data-cy={dataCy}
                    iStyles={iStyles}
                    autoFocus={focus}
                    iBorder={iBorder}
                    data-email={email}
                    data-range={range}
                    data-float={float}
                    value={getValue(value) || ''}
                    disabled={disabled}
                    iPadding={iPadding}
                    iOutline={iOutline}
                    data-format={format}
                    type={type || 'text'}
                    iFontSize={iFontSize}
                    data-index={dataIndex}
                    data-numeric={numeric}
                    data-equal-to={equalTo}
                    data-minimum={minimum}
                    data-maximum={maximum}
                    data-letters={letters}
                    iMinHeight={iMinHeight}
                    onChange={handleChange}
                    iTextAlign={iTextAlign}
                    iTransform={iTransform}
                    data-required={required}
                    alt={iAlt && getValue(value)}
                    data-disabled={disabled}
                    data-object={dataObject}
                    className='wow-ft-input'
                    iFontFamily={iFontFamily}
                    placeholder={placeholder}
                    data-name-array={nameArray}
                    iBorderError={iBorderError}
                    iBorderFocus={iBorderFocus}
                    iBorderRadius={iBorderRadius}
                    valLabel={iTitle || getValue(value)}
                    iBorderSuccess={iBorderSuccess}
                    iColorDisabled={iColorDisabled}
                    iCursorDisabled={iCursorDisabled}
                    iBackgroundColor={iBackgroundColor}
                    autoComplete={autoComplete || 'on'}
                    iFontFamilyDisabled={iFontFamilyDisabled}
                    iColorDisabledClick={iColorDisabledClick}
                    iCursorDisabledClick={iCursorDisabledClick}
                    iHoverDisplayTooltip={iHoverDisplayTooltip}
                    iBackgroundColorDisabled={iBackgroundColorDisabled}
                    iFontFamilyDisabledClick={iFontFamilyDisabledClick}
                    iActiveTitle={configs?.input?.activeTitle || iActiveTitle}
                    iBackgroundColorDisabledClick={iBackgroundColorDisabledClick}
                    onPointerMove={e => (configs?.input?.activeTitle || iActiveTitle) && setAxisInput({ x: e.clientX, y: e.clientY })}
                    onPointerLeave={() => { if (axis.x || axis.y && (configs?.input?.activeTitle || iActiveTitle)) setAxis({}); setAxisInput({}); setAxisL({}); setAxisLabel({}) }}
                />
            </BoxInput>
            {!!(symbol && value) &&
                <SymbolSpan
                    sLeft={sLeft}
                    click={click}
                    sColor={sColor}
                    sStyles={sStyles}
                    sBottom={sBottom}
                    disabled={disabled}
                    sFontSize={sFontSize}
                    sPosition={sPosition}
                    sFontFamily={sFontFamily}
                    className='wow-ft-input-symbol'
                    sColorDisabled={sColorDisabled}
                    gs={configs}
                    sColorDisabledClick={sColorDisabledClick}
                    sFontFamilyDisabled={sFontFamilyDisabled}
                    sFontFamilyDisabledClick={sFontFamilyDisabledClick}
                >
                    {symbol}
                </SymbolSpan>
            }
            {isLoading && ((loadingContainer || configs.loading?.container) ||
                <Loading
                    size={sizeLoading || configs.loading?.size || '20px'}
                    right={rightLoading || configs.loading?.right || '0'}
                    bottom={bottomLoading || configs.loading?.bottom || '0'}
                    color={colorLoading || configs.loading?.color}
                    left={configs.loading?.left}
                    position={configs.loading?.position}
                    top={configs.loading?.top}
                />)
            }
            {errors &&
                <Tooltip
                    tTop={tTop}
                    tLeft={tLeft}
                    tColor={tColor}
                    tZIndex={tZIndex}
                    tBorder={tBorder}
                    tBottom={tBottom}
                    tStyles={tStyles}
                    tDisplay={tDisplay}
                    tPadding={tPadding}
                    tPosition={tPosition}
                    tFontSize={tFontSize}
                    tBoxShadow={tBoxShadow}
                    tFontFamily={tFontFamily}
                    tBorderRadius={tBorderRadius}
                    className='wow-ft-input-tooltip'
                    gs={configs}
                    tTopAfterBefore={tTopAfterBefore}
                    tLeftAfterBefore={tLeftAfterBefore}
                    tBackgroundColor={tBackgroundColor}
                    tBorderAfterBefore={tBorderAfterBefore}
                    tBottomAfterBefore={tBottomAfterBefore}
                    tContentAfterBefore={tContentAfterBefore}
                    tPositionAfterBefore={tPositionAfterBefore}
                    tPointerEventsAfterBefore={tPointerEventsAfterBefore}
                >
                    {message}
                </Tooltip>}
        </ContainerInput>
    )
}