/* eslint-disable no-unused-vars */
/**
 * @description Class containing the initial configuration style input
 * @author ConfigInitTable?
 * @version 1.0.0
 */
'use strict';
// Modules
import * as CSS from 'csstype'

// Others
import { InitConfigColor } from '../../utils/colors'
const Colors = InitConfigColor.getColors()
import { numberFormat } from '../../utils'
import { CSSObject } from 'styled-components';

// Declare type
export declare type TDefaultInputStyle = {
    container?: {
        width?: string
        margin?: string
        minWidth?: string
        maxWidth?: string
        styles?: CSSObject
        widthPhone?: string
        widthTable?: string
        mediaTable?: boolean
        mediaPhone?: boolean
        maxWidthTable?: string
        maxWidthPhone?: string
        stylesTable?: CSSObject
        stylesPhone?: CSSObject
        display?: CSS.Property.Display
        position?: CSS.Property.Position
        flexDirection?: CSS.Property.FlexDirection
        hoverDisplayTooltip?: CSS.Property.Display
    }
    label?: {
        color?: string
        margin?: string
        border?: string
        padding?: string
        fontSize?: string
        styles?: CSSObject
        fontFamily?: string
        colorError?: string
        activeTitle?: boolean
        colorDisabled?: string
        colorNotValue?: string
        overflow?: CSS.Property.Overflow
        textAlign?: CSS.Property.TextAlign
        whiteSpace?: CSS.Property.WhiteSpace
        textOverflow?: CSS.Property.TextOverflow
        hoverDisplayTooltip?: CSS.Property.Display
    }
    input?: {
        width?: string
        color?: string
        border?: string
        padding?: string
        fontSize?: string
        minHeight?: string
        styles?: CSSObject
        fontFamily?: string
        borderError?: string
        borderFocus?: string
        activeTitle?: boolean
        borderRadius?: string
        colorDisabled?: string
        borderSuccess?: string
        backgroundColor?: string
        colorDisabledClick?: string
        fontFamilyDisabled?: string
        outline?: CSS.Property.Outline
        fontFamilyDisabledClick?: string
        textAlign?: CSS.Property.TextAlign
        backgroundColorDisabled?: string
        cursorDisabled?: CSS.Property.Cursor
        transform?: CSS.Property.TextTransform
        backgroundColorDisabledClick?: string
        cursorDisabledClick?: CSS.Property.Cursor
        hoverDisplayTooltip?: CSS.Property.Display
    }
    tooltip?: {
        top?: string
        left?: string
        color?: string
        zIndex?: string
        border?: string
        bottom?: string
        padding?: string
        fontSize?: string
        styles?: CSSObject
        boxShadow?: string
        fontFamily?: string
        borderRadius?: string
        topAfterBefore?: string
        leftAfterBefore?: string
        backgroundColor?: string
        borderAfterBefore?: string
        bottomAfterBefore?: string
        display?: CSS.Property.Display
        position?: CSS.Property.Position
        contentAfterBefore?: CSS.Property.Content
        positionAfterBefore?: CSS.Property.Position
        pointerEventsAfterBefore?: CSS.Property.PointerEvents
    }
    symbol?: {
        left?: string
        color?: string
        bottom?: string
        position?: string
        fontSize?: string
        styles?: CSSObject
        fontFamily?: string
        colorDisabled?: string
        colorDisabledClick?: string
        fontFamilyDisabled?: string
        fontFamilyDisabledClick?: string
    }
    messageError?: {
        pass?: string
        email?: string
        letter?: string
        numeric?: string
        required?: string
        date?: (value: string) => string
        hour?: (value: string) => string
        minimum?: (value: number) => string
        maximum?: (value: number) => string
        range?: (min: number, max: number) => string
    }
    loading?: {
        top?: string
        left?: string
        right?: string
        color?: string
        container?: Node
        bottom?: string
        position?: string
        size?: string | number
    }
    tooltipInput?: {
        color?: string
        zIndex?: string
        opacity?: string
        padding?: string
        fontSize?: string
        maxWidth?: string
        maxHeight?: string
        styles?: CSSObject
        fontFamily?: string
        borderRadius?: string
        backgroundColor?: string
        position?: CSS.Property.Position
        overflow?: CSS.Property.Overflow
        whiteSpace?: CSS.Property.WhiteSpace
        visibility?: CSS.Property.Visibility
        textOverflow?: CSS.Property.TextOverflow
    }
    tooltipLabel?: {
        color?: string
        zIndex?: string
        opacity?: string
        padding?: string
        fontSize?: string
        maxWidth?: string
        styles?: CSSObject
        maxHeight?: string
        fontFamily?: string
        borderRadius?: string
        backgroundColor?: string
        position?: CSS.Property.Position
        overflow?: CSS.Property.Overflow
        whiteSpace?: CSS.Property.WhiteSpace
        visibility?: CSS.Property.Visibility
        textOverflow?: CSS.Property.TextOverflow
    }
    boxInput?: {
        styles?: CSSObject
    }
}

const defaultStyle: TDefaultInputStyle = {
    container: {
        width: '100%',
        display: 'flex',
        widthPhone: '100%',
        widthTable: '100%',
        position: 'relative',
        maxWidthTable: '768px',
        maxWidthPhone: '375px',
        flexDirection: 'column',
        hoverDisplayTooltip: 'block'
    },
    label: {
        border: 'none',
        margin: '1px 0',
        fontSize: '13px',
        activeTitle: true,
        textAlign: 'start',
        overflow: 'hidden',
        padding: '5.2px 0px',
        whiteSpace: 'nowrap',
        fontFamily: 'sans-serif',
        textOverflow: 'ellipsis',
        color: Colors.input?.label,
        hoverDisplayTooltip: 'block',
        colorError: Colors.input?.labelError,
        colorNotValue: Colors.input?.labelLight,
        colorDisabled: Colors.input?.labelHight,
    },
    input: {
        outline: '0',
        width: '100%',
        padding: '0 7px',
        fontSize: '13px',
        minHeight: '36px',
        activeTitle: true,
        borderRadius: '5px',
        fontFamily: 'sans-serif',
        cursorDisabled: 'no-drop',
        color: Colors.input?.input,
        hoverDisplayTooltip: 'block',
        cursorDisabledClick: 'pointer',
        fontFamilyDisabled: 'sans-serif',
        fontFamilyDisabledClick: 'sans-serif',
        colorDisabled: Colors.input?.inputHight,
        colorDisabledClick: Colors.input?.primary,
        border: `1px solid ${Colors.input?.inputBorder}`,
        borderFocus: `1px solid ${Colors.input?.primary}`,
        backgroundColor: Colors.input?.inputBackgroundColor,
        borderError: `1px solid ${Colors.input?.inputBorderError}`,
        borderSuccess: `1px solid ${Colors.input?.inputBorderSuccess}`,
        backgroundColorDisabled: Colors.input?.inputBackgroundColorDisabled,
        backgroundColorDisabledClick: Colors.input?.inputBackgroundColorDisabledClick,
    },
    tooltip: {
        left: '0px',
        zIndex: '10',
        bottom: '-35%',
        display: 'none',
        fontSize: '11px',
        borderRadius: '2px',
        padding: '2px 10px',
        position: 'absolute',
        topAfterBefore: '100%',
        leftAfterBefore: '85%',
        fontFamily: 'sans-serif',
        positionAfterBefore: 'absolute',
        pointerEventsAfterBefore: 'none',
        color: Colors.input?.tooltipColor,
        borderAfterBefore: 'solid transparent',
        border: `1px solid ${Colors.input?.tooltipBorder}`,
        backgroundColor: Colors.input?.tooltipBackgroundColor,
        boxShadow: `0px 0px 3px 1px ${Colors.input?.tooltipColorBoxShadow}`,
    },
    symbol: {
        left: '10px',
        bottom: '10px',
        fontSize: '13px',
        position: 'absolute',
        fontFamily: 'sans-serif',
        color: Colors.input?.symbol,
        fontFamilyDisabled: 'sans-serif',
        fontFamilyDisabledClick: 'sans-serif',
        colorDisabled: Colors.input?.symbolHight,
        colorDisabledClick: Colors.input?.primary,
    },
    messageError: {
        required: 'Campo requerido.',
        numeric: 'Solo puede contener números',
        email: 'El formato de email no es válido',
        letter: 'El campo debe contener solo letra0s.',
        minimum: (value: number) => `El valor es mínimo: ${numberFormat(Number(value))}.`,
        maximum: (value: number) => `El valor es máximo: ${numberFormat(Number(value))}.`,
        date: (value: string) => `La fecha no es valida: ${numberFormat(Number(value))}.`,
        hour: (value: string) => `La hora no es validad: ${numberFormat(Number(value))}.`,
        range: (min: number, max: number) => `El rango de caracteres es de ${min} a ${max}.`,
        pass: 'La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula. Puede tener otros símbolos.',
    },
    loading: {
        color: Colors.loading?.color
    },
    tooltipInput: {
        zIndex: '1',
        opacity: '0',
        padding: '5px',
        color: '#fff',
        maxWidth: '150px',
        fontSize: '10px',
        position: 'fixed',
        maxHeight: '80px',
        borderRadius: '4px',
        visibility: 'hidden',
        fontFamily: 'sans-serif',
        backgroundColor: '#00000099',
    },
    tooltipLabel: {
        zIndex: '1',
        opacity: '0',
        padding: '5px',
        color: '#fff',
        maxWidth: '150px',
        fontSize: '10px',
        position: 'fixed',
        maxHeight: '80px',
        borderRadius: '4px',
        visibility: 'hidden',
        fontFamily: 'sans-serif',
        backgroundColor: '#00000099',
    }
}

// Config init input
export class InitConfigInput {
    private static defaultStyles: TDefaultInputStyle = defaultStyle

    // Getters
    static getStyles(): TDefaultInputStyle {
        return this.defaultStyles
    }

    // Setter
    static configStyles(newStyles: TDefaultInputStyle): void {
        this.defaultStyles = {
            container: {
                ...this.defaultStyles.container,
                ...(newStyles?.container || {})
            },
            label: {
                ...this.defaultStyles.label,
                ...(newStyles?.label || {})
            },
            tooltip: {
                ...this.defaultStyles.tooltip,
                ...(newStyles?.tooltip || {})
            },
            input: {
                ...this.defaultStyles.input,
                ...(newStyles?.input || {})
            },
            symbol: {
                ...this.defaultStyles.symbol,
                ...(newStyles?.symbol || {})
            },
            messageError: {
                ...this.defaultStyles.messageError,
                ...(newStyles?.messageError || {})
            },
            loading: {
                ...this.defaultStyles.loading,
                ...(newStyles?.loading || {})
            },
            tooltipInput: {
                ...this.defaultStyles.tooltipInput,
                ...(newStyles?.tooltipInput || {})
            },
            tooltipLabel: {
                ...this.defaultStyles.tooltipLabel,
                ...(newStyles?.tooltipLabel || {})
            },
            boxInput: {
                ...this.defaultStyles.boxInput,
                ...(newStyles?.boxInput || {})
            }
        }
    }
}