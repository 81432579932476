/* eslint-disable @typescript-eslint/no-explicit-any */
// Modules
import React, { useEffect, useState, FC, ChangeEvent, ReactElement } from 'react'

// Components, Utils, Styles and Types
import { ContainerTextArea, TextAreaV, LabelTextArea, Tooltip, SymbolSpan, BoxTextArea } from './styled'
import { InitConfigTextArea, TDefaultTextAreaStyle } from './settings'
import { isNull, rangeLength, resolveData } from '../../utils/index'
import { Loading } from '../Loading'
import { TTextArea } from './types'

/**
 * Input components
 * @version 0.0.1
 * ```ts
 * @param {TTextArea} props properties Input
 * @return {ReactElement<TTextArea>} node Input
 * ```
 */
export const TextArea: FC<TTextArea> = ({
    name,
    form,
    rows,
    click,
    value,
    title,
    error,
    range,
    focus,
    symbol,
    dataCy,
    onBlur,
    onClick,
    disabled,
    onChange,
    required,
    dataIndex,
    reference,
    nameArray,
    isLoading,
    dataExtra,
    widthTable,
    widthPhone,
    mediaTable,
    mediaPhone,
    dataObject,
    autoCorrect,
    placeholder,
    autoComplete,
    maxWidthTable,
    maxWidthPhone,
    validation = true,
    cWidth,
    cMargin,
    cStyles,
    cDisplay,
    cMaxWidth,
    cMinWidth,
    cPosition,
    cFlexDirection,
    cHoverDisplayTooltip,
    lTitle,
    lStyles,
    lBorder,
    lMargin,
    lPadding,
    lOverflow,
    lNotValue,
    lFontSize,
    lTextAlign,
    lWhiteSpace,
    lFontFamily,
    lErrorColor,
    lTextOverflow,
    lNotValueColor,
    lActiveTitle,
    lHoverDisplayTooltip,
    lColor,
    taColor,
    taTitle,
    taWidth,
    taBorder,
    taStyles,
    taPadding,
    taOutline,
    taFontSize,
    taTextAlign,
    taTransform,
    taMinHeight,
    taFontFamily,
    taBorderError,
    taBorderFocus,
    taBorderRadius,
    taBorderSuccess,
    taColorDisabled,
    taCursorDisabled,
    taBackgroundColor,
    taActiveTitle,
    taFontFamilyDisabled,
    taColorDisabledClick,
    taHoverDisplayTooltip,
    taCursorDisabledClick,
    taFontFamilyDisabledClick,
    taBackgroundColorDisabled,
    taBackgroundColorDisabledClick,
    sLeft,
    sColor,
    sBottom,
    sStyles,
    sFontSize,
    sPosition,
    sFontFamily,
    sColorDisabled,
    sColorDisabledClick,
    sFontFamilyDisabled,
    sFontFamilyDisabledClick,
    tTop,
    tLeft,
    tColor,
    tZIndex,
    tBorder,
    tBottom,
    tPadding,
    tFontSize,
    tBoxShadow,
    tFontFamily,
    tBorderRadius,
    tTopAfterBefore,
    tLeftAfterBefore,
    tBackgroundColor,
    tBorderAfterBefore,
    tBottomAfterBefore,
    tContentAfterBefore,
    tDisplay,
    tPosition,
    tStyles,
    tPositionAfterBefore,
    tPointerEventsAfterBefore,
    sizeLoading,
    rightLoading,
    colorLoading,
    bottomLoading,
    loadingContainer,
    errorMessageRequired,
    errorMessageRange,
    tiColor,
    tiZIndex,
    tiStyles,
    tiOpacity,
    tiPadding,
    tiFontSize,
    tiMaxWidth,
    tiMaxHeight,
    tiFontFamily,
    tiBorderRadius,
    tiBackgroundColor,
    tiPosition,
    tiOverflow,
    tiWhiteSpace,
    tiTextOverflow,
    tlColor,
    tlZIndex,
    tlStyles,
    tlOpacity,
    tlPadding,
    tlFontSize,
    tlMaxWidth,
    tlMaxHeight,
    tlFontFamily,
    tlBorderRadius,
    tlBackgroundColor,
    tlPosition,
    tlOverflow,
    tlWhiteSpace,
    tlTextOverflow,
    bStyles,
}: TTextArea): ReactElement<TTextArea> => {
    // Initial variables
    const [errors, setErrors] = useState<boolean | undefined>(error)
    const [axis, setAxis] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisInput, setAxisInput] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisL, setAxisL] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [axisLabel, setAxisLabel] = useState<{ x?: number, y?: number }>({ x: 0, y: 0 })
    const [message, setMessage] = useState<string>('El campo no debe estar vacío')
    const [valueInput, setValueInput] = useState<any>()
    const [configs] = useState<TDefaultTextAreaStyle>(InitConfigTextArea.getStyles())

    useEffect(() => {
        setErrors(error)
    }, [error])

    useEffect(() => {
        setValueInput(getValue(value))
    }, [value])

    useEffect(() => {
        let timeInput = setTimeout(() => undefined)
        if (!axis.x && !axis.y && axisInput?.x && axisInput?.y && (configs?.textarea?.activeTitle || taActiveTitle)) {
            timeInput = setTimeout(() => setAxis({ x: Number(axisInput?.x) + 10, y: Number(axisInput?.y) + 15 }), 800)
        }
        return () => clearTimeout(timeInput)
    }, [axisInput])

    useEffect(() => {
        let timeLabel = setTimeout(() => undefined)
        if (!axisL.x && !axisL.y && axisLabel?.x && axisLabel?.y && (configs?.label?.activeTitle || lActiveTitle)) {
            timeLabel = setTimeout(() => setAxisL({ x: Number(axisLabel?.x) + 10, y: Number(axisLabel?.y) + 15 }), 800)
        }
        return () => clearTimeout(timeLabel)
    }, [axisLabel])

    /**
     * @description What function to validate the text fields by the onChange method
     * @version 0.0.1
     * @param {object} e change method event
     * @return {void}
     *
    */
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let valError = true

        // Validate that the field is not null
        if (required && validation) {
            if (isNull(e?.target?.value)) {
                valError = false
                setMessage(errorMessageRequired || configs.messageError?.required || '')
            }
        }

        // Validate that the field is in the correct range
        if (!!String(e?.target?.value).length && !!range && validation) {
            if (rangeLength(e?.target?.value, range[0], range[1])) {
                valError = false
                setMessage(errorMessageRange ? errorMessageRange(range[0], range[1]) : configs.messageError?.range && configs.messageError?.range(range[0], range[1]) || '')
            }
        }

        valError && setMessage('')
        setErrors(!valError)
        onChange && onChange({
            name: String(name),
            range,
            required,
            event: e,
            nameArray,
            dataExtra,
            value: e?.target?.value,
            error: !valError,
            index: dataIndex,
            nameObject: dataObject
        })
    }

    /**
     * check if the data type of the textarea
     * @param {any} param param
     * @return {any} new value
     */
    const getValue = (param: any): any => {
        if (axisL.x || axisL.y) setAxisL({})
        if (axis.x || axis.y) setAxis({})
        if (dataObject && typeof param === 'object') {
            const resData = resolveData(param, Number(dataIndex), nameArray, String(name), false, dataObject?.split('.'))
            return resData
        } else {
            if (typeof param === 'object') return param?.[String(name)]
            return param
        }
    }

    return (
        <ContainerTextArea
            gs={configs}
            cMargin={cMargin}
            cDisplay={cDisplay}
            cMinWidth={cMinWidth}
            cMaxWidth={cMaxWidth}
            cPosition={cPosition}
            widthTable={widthTable}
            widthPhone={widthPhone}
            mediaTable={mediaTable}
            mediaPhone={mediaPhone}
            cWidth={cWidth || '100%'}
            maxWidthTable={maxWidthTable}
            maxWidthPhone={maxWidthPhone}
            cFlexDirection={cFlexDirection}
            className='wow-ft-textarea-container'
            cHoverDisplayTooltip={cHoverDisplayTooltip}
            onClick={!isLoading ? onClick : () => null}
            cStyles={cStyles}
        >
            {!lNotValue &&
                <LabelTextArea
                    axis={axisL}
                    gs={configs}
                    error={errors}
                    lColor={lColor}
                    val={valueInput}
                    lBorder={lBorder}
                    lStyles={lStyles}
                    lMargin={lMargin}
                    lPadding={lPadding}
                    lOverflow={lOverflow}
                    lFontSize={lFontSize}
                    lTextAlign={lTextAlign}
                    lWhiteSpace={lWhiteSpace}
                    lFontFamily={lFontFamily}
                    lErrorColor={lErrorColor}
                    valLabel={lTitle || title}
                    lTextOverflow={lTextOverflow}
                    className='wow-ft-textarea-label'
                    lNotValueColor={lNotValueColor}
                    lHoverDisplayTooltip={lHoverDisplayTooltip}
                    lActiveTitle={configs?.label?.activeTitle || lActiveTitle}
                    onPointerMove={e => (configs?.label?.activeTitle || lActiveTitle) && setAxisLabel({ x: e.clientX, y: e.clientY })}
                    onPointerLeave={() => { if (axisL.x || axisL.y && (configs?.label?.activeTitle || lActiveTitle)) { setAxis({}); setAxisInput({}); setAxisL({}); setAxisLabel({}) } }}
                    tlColor={tlColor}
                    tlZIndex={tlZIndex}
                    tlStyles={tlStyles}
                    tlOpacity={tlOpacity}
                    tlPadding={tlPadding}
                    tlFontSize={tlFontSize}
                    tlMaxWidth={tlMaxWidth}
                    tlPosition={tlPosition}
                    tlOverflow={tlOverflow}
                    tlMaxHeight={tlMaxHeight}
                    tlFontFamily={tlFontFamily}
                    tlWhiteSpace={tlWhiteSpace}
                    tlTextOverflow={tlTextOverflow}
                    tlBorderRadius={tlBorderRadius}
                    tlBackgroundColor={tlBackgroundColor}
                >
                    {title}
                </LabelTextArea>
            }
            <BoxTextArea
                axis={axis}
                gs={configs}
                bStyles={bStyles}
                tiColor={tiColor}
                tiZIndex={tiZIndex}
                tiStyles={tiStyles}
                tiOpacity={tiOpacity}
                tiPadding={tiPadding}
                tiFontSize={tiFontSize}
                tiMaxWidth={tiMaxWidth}
                tiPosition={tiPosition}
                tiOverflow={tiOverflow}
                tiMaxHeight={tiMaxHeight}
                val={taTitle || valueInput}
                tiFontFamily={tiFontFamily}
                tiWhiteSpace={tiWhiteSpace}
                tiTextOverflow={tiTextOverflow}
                tiBorderRadius={tiBorderRadius}
                className='wow-ft-select-box-textarea'
                tiBackgroundColor={tiBackgroundColor}
                taActiveTitle={configs?.textarea?.activeTitle || taActiveTitle}
            >
                <TextAreaV
                    name={name}
                    form={form}
                    rows={rows}
                    axis={axis}
                    gs={configs}
                    click={click}
                    taColor={taColor}
                    taWidth={taWidth}
                    error={errors}
                    ref={reference}
                    onBlur={onBlur}
                    data-cy={dataCy}
                    autoFocus={focus}
                    data-range={range}
                    taStyles={taStyles}
                    disabled={disabled}
                    taBorder={taBorder}
                    data-type='textarea'
                    taPadding={taPadding}
                    taOutline={taOutline}
                    data-index={dataIndex}
                    taFontSize={taFontSize}
                    onChange={handleChange}
                    value={valueInput || ''}
                    data-required={required}
                    data-disabled={disabled}
                    data-object={dataObject}
                    autoCorrect={autoCorrect}
                    taMinHeight={taMinHeight}
                    taTextAlign={taTextAlign}
                    taTransform={taTransform}
                    placeholder={placeholder}
                    className='wow-ft-textarea'
                    taFontFamily={taFontFamily}
                    data-name-array={nameArray}
                    taBorderError={taBorderError}
                    taBorderFocus={taBorderFocus}
                    taBorderRadius={taBorderRadius}
                    valLabel={taTitle || valueInput}
                    taBorderSuccess={taBorderSuccess}
                    taColorDisabled={taColorDisabled}
                    autoComplete={autoComplete || 'on'}
                    taCursorDisabled={taCursorDisabled}
                    taBackgroundColor={taBackgroundColor}
                    taFontFamilyDisabled={taFontFamilyDisabled}
                    taColorDisabledClick={taColorDisabledClick}
                    taCursorDisabledClick={taCursorDisabledClick}
                    taHoverDisplayTooltip={taHoverDisplayTooltip}
                    taBackgroundColorDisabled={taBackgroundColorDisabled}
                    taFontFamilyDisabledClick={taFontFamilyDisabledClick}
                    taActiveTitle={configs?.textarea?.activeTitle || taActiveTitle}
                    taBackgroundColorDisabledClick={taBackgroundColorDisabledClick}
                    onPointerMove={e => (configs?.textarea?.activeTitle || taActiveTitle) && setAxisInput({ x: e.clientX, y: e.clientY })}
                    onPointerLeave={() => { if (axis.x || axis.y && (configs?.textarea?.activeTitle || taActiveTitle)) setAxis({}); setAxisInput({}); setAxisL({}); setAxisLabel({}) }}
                />
            </BoxTextArea>
            {!!(symbol && value) &&
                <SymbolSpan
                    gs={configs}
                    sLeft={sLeft}
                    click={click}
                    sColor={sColor}
                    sStyles={sStyles}
                    sBottom={sBottom}
                    disabled={disabled}
                    sFontSize={sFontSize}
                    sPosition={sPosition}
                    sFontFamily={sFontFamily}
                    className='wow-ft-textarea-symbol'
                    sColorDisabled={sColorDisabled}
                    sColorDisabledClick={sColorDisabledClick}
                    sFontFamilyDisabled={sFontFamilyDisabled}
                    sFontFamilyDisabledClick={sFontFamilyDisabledClick}
                >
                    {symbol}
                </SymbolSpan>
            }
            {isLoading && ((loadingContainer || configs.loading?.container) ||
                <Loading
                    top={configs.loading?.top}
                    left={configs.loading?.left}
                    position={configs.loading?.position}
                    color={colorLoading || configs.loading?.color}
                    size={sizeLoading || configs.loading?.size || '20px'}
                    right={rightLoading || configs.loading?.right || '0'}
                    bottom={bottomLoading || configs.loading?.bottom || '0'}
                />)
            }
            {errors &&
                <Tooltip
                    tTop={tTop}
                    gs={configs}
                    tLeft={tLeft}
                    tColor={tColor}
                    tZIndex={tZIndex}
                    tBorder={tBorder}
                    tBottom={tBottom}
                    tStyles={tStyles}
                    tDisplay={tDisplay}
                    tPadding={tPadding}
                    tPosition={tPosition}
                    tFontSize={tFontSize}
                    tBoxShadow={tBoxShadow}
                    tFontFamily={tFontFamily}
                    tBorderRadius={tBorderRadius}
                    className='wow-ft-textarea-tooltip'
                    tTopAfterBefore={tTopAfterBefore}
                    tLeftAfterBefore={tLeftAfterBefore}
                    tBackgroundColor={tBackgroundColor}
                    tBorderAfterBefore={tBorderAfterBefore}
                    tBottomAfterBefore={tBottomAfterBefore}
                    tContentAfterBefore={tContentAfterBefore}
                    tPositionAfterBefore={tPositionAfterBefore}
                    tPointerEventsAfterBefore={tPointerEventsAfterBefore}
                >
                    {message}
                </Tooltip>}
        </ContainerTextArea>
    )
}