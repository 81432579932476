import styled, { css } from 'styled-components'
import { TTooltip, TContainerInput, TLabelInput, TInputV, TSymbolSpan, TBoxInput } from './types'

// Estilos
export const Tooltip = styled.div<TTooltip>`
    left: ${({ gs, tLeft }) => tLeft || gs?.tooltip?.left};
    color: ${({ gs, tColor }) => tColor || gs?.tooltip?.color};
    border: ${({ gs, tBorder }) => tBorder || gs?.tooltip?.border};
    z-index: ${({ gs, tZIndex }) => tZIndex || gs?.tooltip?.zIndex};
    display: ${({ gs, tDisplay }) => tDisplay || gs?.tooltip?.display};
    padding: ${({ gs, tPadding }) => tPadding || gs?.tooltip?.padding};
    font-size: ${({ gs, tFontSize }) => tFontSize || gs?.tooltip?.fontSize};
    position: ${({ gs, tPosition }) => tPosition || gs?.tooltip?.position};
    box-shadow: ${({ gs, tBoxShadow }) => tBoxShadow || gs?.tooltip?.boxShadow};
    font-family: ${({ gs, tFontFamily }) => tFontFamily || gs?.tooltip?.fontFamily};
    border-radius: ${({ gs, tBorderRadius }) => tBorderRadius || gs?.tooltip?.borderRadius};
    ${({ gs, tTop }) => (tTop || gs?.tooltip?.top) && css`top: ${tTop || gs?.tooltip?.top};`}
    background-color: ${({ gs, tBackgroundColor }) => tBackgroundColor || gs?.tooltip?.backgroundColor};
    ${({ gs, tBottom }) => (tBottom || gs?.tooltip?.bottom) && css`bottom: ${tBottom || gs?.tooltip?.bottom};`}
    &::after, &::before {
        left: ${({ gs, tLeftAfterBefore }) => tLeftAfterBefore || gs?.tooltip?.leftAfterBefore};
        position: ${({ gs, tPositionAfterBefore }) => tPositionAfterBefore || gs?.tooltip?.position};
        border: ${({ gs, tBorderAfterBefore }) => tBorderAfterBefore || gs?.tooltip?.borderAfterBefore};
        ${({ gs, tTopAfterBefore }) => (tTopAfterBefore || gs?.tooltip?.topAfterBefore) && css`top: ${tTopAfterBefore || gs?.tooltip?.topAfterBefore};`}
        pointer-events: ${({ gs, tPointerEventsAfterBefore }) => tPointerEventsAfterBefore || gs?.tooltip?.pointerEventsAfterBefore};
        ${({ gs, tBottomAfterBefore }) => (tBottomAfterBefore || gs?.tooltip?.bottomAfterBefore) && css`bottom: ${tBottomAfterBefore || gs?.tooltip?.bottomAfterBefore};`}
        ${({ gs, tContentAfterBefore }) => (tContentAfterBefore || gs?.tooltip?.contentAfterBefore) && css`content: ${tContentAfterBefore || gs?.tooltip?.contentAfterBefore};`}
    }
    ${({ tStyles }) => tStyles};
    ${({ gs }) => gs?.tooltip?.styles};
`
export const ContainerInput = styled.div<TContainerInput>`
    width: ${({ gs, cWidth }) => cWidth || gs?.container?.width};
    display: ${({ gs, cDisplay }) => cDisplay || gs?.container?.display};
    position: ${({ gs, cPosition }) => cPosition || gs?.container?.position};
    flex-direction: ${({ gs, cFlexDirection }) => cFlexDirection || gs?.container?.flexDirection};
    ${({ gs, cMargin }) => (cMargin || gs?.container?.margin) && css`margin: ${cMargin || gs?.container?.margin};`}
    ${({ gs, cMinWidth }) => (cMinWidth || gs?.container?.minWidth) && css`min-width: ${cMinWidth || gs?.container?.minWidth};`}
    ${({ gs, cMaxWidth }) => (cMaxWidth || gs?.container?.maxWidth) && css`min-width: ${cMaxWidth || gs?.container?.maxWidth};`}
    &:hover > ${Tooltip} { display: ${({ gs, cHoverDisplayTooltip }) => cHoverDisplayTooltip || gs?.container?.hoverDisplayTooltip}; }
    ${({ mediaTable, gs, maxWidthTable, widthTable }) => (mediaTable || gs?.container?.mediaTable) && css`
        @media only screen and (max-width: ${ maxWidthTable || gs?.container?.maxWidthTable}) {
            width: ${ widthTable || gs?.container?.widthTable};
        }
    ` }
    ${({ mediaPhone, gs, widthPhone, maxWidthPhone }) => (mediaPhone || gs?.container?.mediaPhone) && css`
        @media only screen and (max-width: ${maxWidthPhone || gs?.container?.maxWidthPhone}) {
            width: ${ widthPhone || gs?.container?.widthPhone};
        }
    ` }
    ${({ cStyles }) => cStyles};
    ${({ gs }) => gs?.container?.styles};
`
export const LabelInput = styled.span<TLabelInput>`
    border: ${({ gs, lBorder }) => lBorder || gs?.label?.border};
    margin: ${({ gs, lMargin }) => lMargin || gs?.label?.margin};
    padding: ${({ gs, lPadding }) => lPadding || gs?.label?.padding};
    font-size: ${({ gs, lFontSize }) => lFontSize || gs?.label?.fontSize};
    text-align: ${({ gs, lTextAlign }) => lTextAlign || gs?.label?.textAlign};
    white-space: ${({ gs, lWhiteSpace }) => lWhiteSpace || gs?.label?.whiteSpace};
    font-family: ${({ gs, lFontFamily }) => lFontFamily || gs?.label?.fontFamily};
    text-overflow: ${({ gs, lTextOverflow }) => lTextOverflow || gs?.label?.textOverflow};
    overflow: ${({ gs, lOverflow }) => lOverflow || gs?.label?.overflow};
    &:hover ~ ${Tooltip} { display: ${({ gs, lHoverDisplayTooltip }) => lHoverDisplayTooltip || gs?.label?.hoverDisplayTooltip}; }
    color: ${({ gs, val, lColor, error, loading, lErrorColor, lNotValueColor }) => error ? (lErrorColor || gs?.label?.colorError) : ((val || loading) ? (lColor || gs?.label?.color) : (lNotValueColor || gs?.label?.colorNotValue))};
    &:hover::after {
        opacity: ${({ lActiveTitle, valLabel, axis }) => (lActiveTitle && valLabel && axis?.y && axis?.x) ? 1 : 0};
        visibility: visible;
    }
    &::after {
        top: ${({ axis }) => axis?.y || -1000}px;
        left: ${({ axis }) => axis?.x || -1000}px;
        color: ${({ tlColor, gs }) => tlColor || gs?.tooltipLabel?.color};
        z-index: ${({ tlZIndex, gs }) => tlZIndex || gs?.tooltipLabel?.zIndex};
        opacity: ${({ tlOpacity, gs }) => tlOpacity || gs?.tooltipLabel?.opacity};
        padding: ${({ tlPadding, gs }) => tlPadding || gs?.tooltipLabel?.padding};
        max-width: ${({ tlMaxWidth, gs }) => tlMaxWidth || gs?.tooltipLabel?.maxWidth};
        position: ${({ tlPosition, gs }) => tlPosition || gs?.tooltipLabel?.position};
        font-size: ${({ tlFontSize, gs }) => tlFontSize || gs?.tooltipLabel?.fontSize};
        max-height: ${({ tlMaxHeight, gs }) => tlMaxHeight || gs?.tooltipLabel?.maxHeight};
        visibility: ${({ tlVisibility, gs }) => tlVisibility || gs?.tooltipLabel?.visibility};
        font-family: ${({ tlFontFamily, gs }) => tlFontFamily || gs?.tooltipLabel?.fontFamily};
        border-radius: ${({ tlBorderRadius, gs }) => tlBorderRadius || gs?.tooltipLabel?.borderRadius};
        background-color: ${({ tlBackgroundColor, gs }) => tlBackgroundColor || gs?.tooltipLabel?.backgroundColor};
        content: ${({ lActiveTitle, valLabel, axis }) => `'${(lActiveTitle && valLabel && axis?.y && axis?.x) ? valLabel : ''}'`};
        ${({ tlOverflow, gs }) => (tlOverflow || gs?.tooltipLabel?.overflow) && css`overflow: ${tlOverflow || gs?.tooltipLabel?.overflow};`}
        ${({ tlWhiteSpace, gs }) => (tlWhiteSpace || gs?.tooltipLabel?.whiteSpace) && css`white-space: ${tlWhiteSpace || gs?.tooltipLabel?.whiteSpace};`}
        ${({ tlTextOverflow, gs }) => (tlTextOverflow || gs?.tooltipLabel?.textOverflow) && css`text-overflow: ${tlTextOverflow || gs?.tooltipLabel?.textOverflow};`}
        ${({ tlStyles }) => tlStyles};
        ${({ gs }) => gs?.tooltipLabel?.styles};
    }
    ${({ lStyles }) => lStyles};
    ${({ gs }) => gs?.label?.styles};
`
export const BoxInput = styled.div<TBoxInput>`
    &:hover::after {
        opacity: ${({ iActiveTitle, val, axis }) => (iActiveTitle && val && axis?.y && axis?.x) ? 1 : 0};
        visibility: visible;
    }
    &::after {
        top: ${({ axis }) => axis?.y || -1000}px;
        left: ${({ axis }) => axis?.x || -1000}px;
        color: ${({ tiColor, gs }) => tiColor || gs?.tooltipInput?.color};
        z-index: ${({ tiZIndex, gs }) => tiZIndex || gs?.tooltipInput?.zIndex};
        opacity: ${({ tiOpacity, gs }) => tiOpacity || gs?.tooltipInput?.opacity};
        padding: ${({ tiPadding, gs }) => tiPadding || gs?.tooltipInput?.padding};
        position: ${({ tiPosition, gs }) => tiPosition || gs?.tooltipInput?.position};
        font-size: ${({ tiFontSize, gs }) => tiFontSize || gs?.tooltipInput?.fontSize};
        max-width: ${({ tiMaxWidth, gs }) => tiMaxWidth || gs?.tooltipInput?.maxWidth};
        max-height: ${({ tiMaxHeight, gs }) => tiMaxHeight || gs?.tooltipInput?.maxHeight};
        visibility: ${({ tiVisibility, gs }) => tiVisibility || gs?.tooltipInput?.visibility};
        font-family: ${({ tiFontFamily, gs }) => tiFontFamily || gs?.tooltipInput?.fontFamily};
        border-radius: ${({ tiBorderRadius, gs }) => tiBorderRadius || gs?.tooltipInput?.borderRadius};
        content: ${({ iActiveTitle, val, axis }) => `'${(iActiveTitle && val && axis?.y && axis?.x) ? val : ''}'`};
        background-color: ${({ tiBackgroundColor, gs }) => tiBackgroundColor || gs?.tooltipInput?.backgroundColor};
        ${({ tiOverflow, gs }) => (tiOverflow || gs?.tooltipInput?.overflow) && css`overflow: ${tiOverflow || gs?.tooltipInput?.overflow};`}
        ${({ tiWhiteSpace, gs }) => (tiWhiteSpace || gs?.tooltipInput?.whiteSpace) && css`white-space: ${tiWhiteSpace || gs?.tooltipInput?.whiteSpace};`}
        ${({ tiTextOverflow, gs }) => (tiTextOverflow || gs?.tooltipInput?.textOverflow) && css`text-overflow: ${tiTextOverflow || gs?.tooltipInput?.textOverflow};`}
        ${({ tiStyles }) => tiStyles};
        ${({ gs }) => gs?.tooltipInput?.styles};
    }
    ${({ bStyles }) => bStyles};
    ${({ gs }) => gs?.boxInput?.styles};
`
export const InputV = styled.input<TInputV>`
    color: ${({ gs, iColor }) => iColor || gs?.input?.color};
    width: ${({ gs, iWidth }) => iWidth || gs?.input?.width};
    padding: ${({ gs, iPadding }) => iPadding || gs?.input?.padding};
    outline: ${({ gs, iOutline }) => iOutline || gs?.input?.outline};
    font-size: ${({ gs, iFontSize }) => iFontSize || gs?.input?.fontSize};
    min-height: ${({ gs, iMinHeight }) => iMinHeight || gs?.input?.minHeight};
    font-family: ${({ gs, iFontFamily }) => iFontFamily || gs?.input?.fontFamily};
    border-radius: ${({ gs, iBorderRadius }) => iBorderRadius || gs?.input?.borderRadius};
    ${({ gs, iTextAlign }) => (iTextAlign || gs?.input?.fontFamily) && css`text-align: ${iTextAlign || gs?.input?.textAlign};`}
    ${({ gs, iTransform }) => (iTransform || gs?.input?.transform) && css`text-transform: ${iTransform || gs?.input?.transform};`}
    ${({ gs, iBackgroundColor }) => (iBackgroundColor || gs?.input?.backgroundColor) && css`background-color: ${iBackgroundColor || gs?.input?.backgroundColor};`}
    border: ${({ gs, iBorder, iBorderError, error, iBorderSuccess, value }) => error ? (iBorderError || gs?.input?.borderError) : (value ? (iBorderSuccess || gs?.input?.borderSuccess) : (iBorder || gs?.input?.border))};
    &:hover ~ ${Tooltip} { display: ${({ gs, iHoverDisplayTooltip }) => iHoverDisplayTooltip || gs?.input?.hoverDisplayTooltip}; }
    &:focus { border: ${({ gs, iBorderFocus }) => iBorderFocus || gs?.input?.borderFocus}; }
    &:disabled {
        color: ${({ gs, click, iColorDisabled, iColorDisabledClick }) => click ? (iColorDisabledClick || gs?.input?.colorDisabledClick) : (iColorDisabled || gs?.input?.colorDisabled)};
        cursor: ${({ gs, click, iCursorDisabled, iCursorDisabledClick }) => click ? (iCursorDisabledClick || gs?.input?.cursorDisabledClick) : (iCursorDisabled || gs?.input?.cursorDisabled)};
        font-family: ${({ gs, click, iFontFamilyDisabled, iFontFamilyDisabledClick }) => click ? (iFontFamilyDisabledClick || gs?.input?.fontFamilyDisabledClick) : (iFontFamilyDisabled || gs?.input?.fontFamilyDisabled)};
        background-color: ${({ gs, click, iBackgroundColorDisabled, iBackgroundColorDisabledClick }) => click ? (iBackgroundColorDisabledClick || gs?.input?.backgroundColorDisabledClick) : (iBackgroundColorDisabled || gs?.input?.backgroundColorDisabled)};
    }
    ${({ iStyles }) => iStyles};
    ${({ gs }) => gs?.input?.styles};
`
export const SymbolSpan = styled.span<TSymbolSpan>`
    left: ${({ gs, sLeft }) => sLeft || gs?.symbol?.left};
    bottom: ${({ gs, sBottom }) => sBottom || gs?.symbol?.bottom};
    position: ${({ gs, sPosition }) => sPosition || gs?.symbol?.position};
    font-size: ${({ gs, sFontSize }) => sFontSize || gs?.symbol?.fontSize};
    color: ${({ gs, click, disabled, sColor, sColorDisabled, sColorDisabledClick }) => click ? (sColorDisabledClick || gs?.symbol?.colorDisabledClick) : (disabled ? (sColorDisabled || gs?.symbol?.colorDisabled) : (sColor || gs?.symbol?.color))};
    font-family: ${({ gs, click, disabled, sFontFamily, sFontFamilyDisabled, sFontFamilyDisabledClick }) => disabled ? (sFontFamilyDisabled || gs?.symbol?.fontFamilyDisabled) : click ? (sFontFamilyDisabledClick || gs?.symbol?.fontFamilyDisabledClick) : (sFontFamily || gs?.symbol?.fontFamily)};
    ${({ sStyles }) => sStyles};
    ${({ gs }) => gs?.symbol?.styles};
`