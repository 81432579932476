/* eslint-disable no-unused-vars */
/**
 * @description Class containing the initial configuration style select
 * @author ConfigInitTable?
 * @version 1.0.0
 */
'use strict';
// Modules
import { GroupTypeBase, Styles, Theme } from 'react-select'
import * as CSS from 'csstype'

// Others
import { InitConfigColor } from '../../utils/colors'
import { CSSObject } from 'styled-components';
const Colors = InitConfigColor.getColors()

// Declare type
export declare type TDefaultSelectStyle = {
    container?: {
        width?: string
        margin?: string
        minWidth?: string
        maxWidth?: string
        styles?: CSSObject
        widthPhone?: string
        widthTable?: string
        mediaPhone?: boolean
        mediaTable?: boolean
        maxWidthTable?: string
        maxWidthPhone?: string
        display?: CSS.Property.Display
        position?: CSS.Property.Position
        flexDirection?: CSS.Property.FlexDirection
        hoverDisplayTooltip?: CSS.Property.Display
    }
    label?: {
        color?: string
        margin?: string
        border?: string
        padding?: string
        fontSize?: string
        styles?: CSSObject
        fontFamily?: string
        colorError?: string
        activeTitle?: boolean
        colorDisabled?: string
        colorNotValue?: string
        overflow?: CSS.Property.Overflow
        textAlign?: CSS.Property.TextAlign
        whiteSpace?: CSS.Property.WhiteSpace
        textOverflow?: CSS.Property.TextOverflow
        hoverDisplayTooltip?: CSS.Property.Display
    }
    select?: {
        activeTitle?: boolean
        styles?: Partial<Styles<{ label: string; value: string; }, boolean, GroupTypeBase<{ label: string; value: string; }>>> | undefined
    }
    tooltip?: {
        top?: string
        left?: string
        color?: string
        zIndex?: string
        border?: string
        bottom?: string
        padding?: string
        fontSize?: string
        styles?: CSSObject
        boxShadow?: string
        fontFamily?: string
        borderRadius?: string
        topAfterBefore?: string
        leftAfterBefore?: string
        backgroundColor?: string
        borderAfterBefore?: string
        bottomAfterBefore?: string
        display?: CSS.Property.Display
        position?: CSS.Property.Position
        contentAfterBefore?: CSS.Property.Content
        positionAfterBefore?: CSS.Property.Position
        pointerEventsAfterBefore?: CSS.Property.PointerEvents
    }
    messageError?: {
        required?: string
    }
    loading?: {
        top?: string
        left?: string
        right?: string
        color?: string
        bottom?: string
        container?: Node
        size?: string | number
        position?: CSS.Property.Position
    }
    theme: Theme
    tooltipSelect?: {
        color?: string
        zIndex?: string
        opacity?: string
        padding?: string
        fontSize?: string
        maxWidth?: string
        maxHeight?: string
        styles?: CSSObject
        fontFamily?: string
        borderRadius?: string
        backgroundColor?: string
        position?: CSS.Property.Position
        overflow?: CSS.Property.Overflow
        visibility?: CSS.Property.Visibility
        whiteSpace?: CSS.Property.WhiteSpace
        textOverflow?: CSS.Property.TextOverflow
    }
    tooltipLabel?: {
        color?: string
        zIndex?: string
        opacity?: string
        padding?: string
        fontSize?: string
        maxWidth?: string
        styles?: CSSObject
        maxHeight?: string
        fontFamily?: string
        borderRadius?: string
        backgroundColor?: string
        position?: CSS.Property.Position
        overflow?: CSS.Property.Overflow
        visibility?: CSS.Property.Visibility
        whiteSpace?: CSS.Property.WhiteSpace
        textOverflow?: CSS.Property.TextOverflow
    }
    boxSelect?: {
        styles?: CSSObject
    }
}

const defaultStyle: TDefaultSelectStyle = {
    container: {
        width: '100%',
        display: 'flex',
        widthPhone: '100%',
        widthTable: '100%',
        position: 'relative',
        maxWidthTable: '768px',
        maxWidthPhone: '375px',
        flexDirection: 'column',
        hoverDisplayTooltip: 'block'
    },
    label: {
        border: 'none',
        margin: '1px 0',
        fontSize: '13px',
        activeTitle: true,
        textAlign: 'start',
        overflow: 'hidden',
        padding: '5.2px 0px',
        whiteSpace: 'nowrap',
        fontFamily: 'sans-serif',
        textOverflow: 'ellipsis',
        color: Colors.select?.label,
        hoverDisplayTooltip: 'block',
        colorError: Colors.select?.labelError,
        colorNotValue: Colors.select?.labelLight,
        colorDisabled: Colors.select?.labelHight,
    },
    select: {
        activeTitle: true
    },
    tooltip: {
        left: '0px',
        zIndex: '10',
        bottom: '-35%',
        display: 'none',
        fontSize: '11px',
        borderRadius: '2px',
        padding: '2px 10px',
        position: 'absolute',
        topAfterBefore: '100%',
        leftAfterBefore: '85%',
        fontFamily: 'sans-serif',
        positionAfterBefore: 'absolute',
        pointerEventsAfterBefore: 'none',
        color: Colors.select?.tooltipColor,
        borderAfterBefore: 'solid transparent',
        border: `1px solid ${Colors.select?.tooltipBorder}`,
        backgroundColor: Colors.select?.tooltipBackgroundColor,
        boxShadow: `0px 0px 3px 1px ${Colors.select?.tooltipColorBoxShadow}`,
    },
    messageError: {
        required: 'Campo requerido.'
    },
    loading: {
        color: Colors.loading?.color
    },
    theme: {
        colors: {
            danger: String(Colors.select?.danger),
            primary: String(Colors.select?.primary),
            primary75: `${Colors.select?.primary}BA`,
            primary50: `${Colors.select?.primary}80`,
            primary25: `${Colors.select?.primary}40`,
            neutral10: String(Colors.select?.neutral10),
            neutral20: String(Colors.select?.neutral20),
            neutral30: String(Colors.select?.neutral30),
            neutral40: String(Colors.select?.neutral40),
            neutral50: String(Colors.select?.neutral50),
            neutral60: String(Colors.select?.neutral60),
            neutral70: String(Colors.select?.neutral70),
            neutral80: String(Colors.select?.neutral80),
            neutral90: String(Colors.select?.neutral90),
            dangerLight: String(Colors.select?.dangerLight),
            neutral0: String(Colors.select?.selectBackgroundColor),
            neutral5: String(Colors.select?.selectBackgroundColorDisabled),
        },
        borderRadius: 4,
        spacing: {
            baseUnit: 3.5,
            controlHeight: 2,
            menuGutter: 3
        }
    },
    tooltipSelect: {
        zIndex: '1',
        opacity: '0',
        padding: '5px',
        color: '#fff',
        maxWidth: '150px',
        fontSize: '10px',
        position: 'fixed',
        maxHeight: '80px',
        borderRadius: '4px',
        visibility: 'hidden',
        fontFamily: 'sans-serif',
        backgroundColor: '#00000099',
    },
    tooltipLabel: {
        zIndex: '1',
        opacity: '0',
        padding: '5px',
        color: '#fff',
        maxWidth: '150px',
        fontSize: '10px',
        position: 'fixed',
        maxHeight: '80px',
        borderRadius: '4px',
        visibility: 'hidden',
        fontFamily: 'sans-serif',
        backgroundColor: '#00000099',
    }
}

// Config init select
export class InitConfigSelect {
    private static defaultStyles: TDefaultSelectStyle = defaultStyle

    // Getters
    static getStyles(): TDefaultSelectStyle {
        return this.defaultStyles
    }

    // Setter
    static configStyles(newStyles: TDefaultSelectStyle): void {
        this.defaultStyles = {
            container: {
                ...this.defaultStyles.container,
                ...(newStyles?.container || {})
            },
            label: {
                ...this.defaultStyles.label,
                ...(newStyles?.label || {})
            },
            tooltip: {
                ...this.defaultStyles.tooltip,
                ...(newStyles?.tooltip || {})
            },
            select: {
                ...this.defaultStyles.select,
                ...(newStyles?.select || {})
            },
            messageError: {
                ...this.defaultStyles.messageError,
                ...(newStyles?.messageError || {})
            },
            loading: {
                ...this.defaultStyles.loading,
                ...(newStyles?.loading || {})
            },
            theme: {
                ...this.defaultStyles.theme,
                ...(newStyles?.theme || {})
            },
            tooltipLabel: {
                ...this.defaultStyles.tooltipLabel,
                ...(newStyles?.tooltipLabel || {})
            },
            tooltipSelect: {
                ...this.defaultStyles.tooltipSelect,
                ...(newStyles?.tooltipSelect || {})
            },
            boxSelect: {
                ...this.defaultStyles.boxSelect,
                ...(newStyles?.boxSelect || {})
            }
        }
    }
}